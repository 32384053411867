<script lang="ts" setup>
import { ref, watch, onBeforeUnmount } from 'vue';
import { ObAlert, ObAlertButton } from '../../components';
import type { Toast } from './shared';

interface Props {
  toast: Toast;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  (event: 'afterHide'): void;
}>();

const showing = ref(true);

let timerId: ReturnType<typeof setTimeout>;

function hide() {
  showing.value = false;
  clearTimeout(timerId);
}

function clearTimer() {
  clearTimeout(timerId);
}

function setTimer() {
  clearTimeout(timerId);

  if (props.toast.duration > 0) {
    timerId = setTimeout(() => {
      showing.value = false;
    }, props.toast.duration);
  }
}

function onMouseenter(): void {
  if (!props.toast.keepAliveOnHover) return;
  clearTimer();
}

function onMouseleave(): void {
  if (!props.toast.keepAliveOnHover) return;
  setTimer();
}

watch(
  () => props.toast.duration,
  () => setTimer(),
  { immediate: true },
);

onBeforeUnmount(() => {
  clearTimeout(timerId);
});

function onBeforeLeave(element: HTMLElement) {
  element.style.maxHeight = `${element.offsetHeight}px`;
}

function onLeave(element: HTMLElement) {
  element.style.maxHeight = '0';
}

function onAfterLeave(element: HTMLElement) {
  element.style.maxHeight = '';
  emit('afterHide');
}

defineExpose({
  hide,
});
</script>

<template>
  <Transition
    appear
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
    @before-leave="onBeforeLeave"
    @leave="onLeave"
    @after-leave="onAfterLeave"
  >
    <div v-if="showing" :class="$style.toast">
      <ObAlert
        :variant="props.toast.variant"
        :closable="props.toast.closable"
        @close="hide()"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave"
      >
        {{ props.toast.content }}
        <template #buttons>
          <ObAlertButton
            v-for="(action, index) in props.toast.actions"
            :key="index"
            @click="() => action.callback(toast)"
          >
            {{ action.label }}
          </ObAlertButton>
        </template>
      </ObAlert>
    </div>
  </Transition>
</template>

<style lang="scss" module>
.toast {
  margin-bottom: 8px;
}

$transition-duration-enter: 0.5s;
$transition-duration-leave: 0.3s;

.enterActive {
  transition: max-height $transition-duration-enter ease-out,
    opacity $transition-duration-enter ease-out, transform $transition-duration-enter ease-out;
}

.leaveActive {
  transition: max-height $transition-duration-leave ease-in,
    opacity $transition-duration-leave ease-in, margin $transition-duration-leave ease-in,
    transform $transition-duration-leave ease-in;
}

.enterFrom {
  opacity: 0;
  transform: translateY(30px);
}

.leaveTo {
  opacity: 0;
  transform: scale(0.3);
  margin-bottom: 0;
}
</style>
