import type { RouteLocationNormalized } from 'vue-router';
import type { Guard, GuardReturn, GuardContext } from './interfaces';

export async function runGuards(
  from: RouteLocationNormalized,
  to: RouteLocationNormalized,
  context: GuardContext,
): Promise<GuardReturn> {
  const guards: Guard[] = [];

  // Get all guards set for current and parent routes
  to.matched.forEach(({ meta }) => {
    if (!meta.guards) return;

    if (Array.isArray(meta.guards)) {
      guards.push(
        ...meta.guards.filter((item) => typeof item === 'function' && !guards.includes(item)),
      );
    }
  });

  // Run every guard until cancel, redirect or error
  for (let i = 0; i < guards.length; i += 1) {
    const result = await guards[i](from, to, context);

    if (typeof result !== 'undefined' && result !== true) {
      return result;
    }
  }

  return undefined;
}
