<script lang="ts" setup>
import { ref, provide, reactive } from 'vue';
import { createUniqueId } from '../../utils';
import { TOASTER_API } from './shared';
import type { Toast, ToastOptions } from './shared';
import ObToastsItem from './ob-toasts-item.vue';

interface Props {
  duration?: number;
  keepAliveOnHover?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  duration: 3000,
  keepAliveOnHover: false,
});

const toasts = ref<Toast[]>([]);

const itemInstances = ref<Record<string, InstanceType<typeof ObToastsItem>>>({});

function remove(key: string) {
  const toast = toasts.value.find((toast) => toast.id === key);

  if (!toast) return;

  toasts.value = toasts.value.filter((toast) => toast.id !== key);

  delete itemInstances.value[toast.id];

  toast.removed = true;
}

function removeAll() {
  Object.values(itemInstances.value).forEach((instance) => {
    instance.hide();
  });
}

function create(content: string, options?: ToastOptions): Toast {
  if (options?.key) {
    toasts.value.filter((toast) => toast.key === options.key).forEach((toast) => toast.remove());
  }

  const id = createUniqueId();

  const toast: Toast = reactive({
    actions: options?.actions ?? [],
    closable: options?.closable ?? false,
    content,
    duration: options?.duration ?? props.duration,
    id,
    keepAliveOnHover: options?.keepAliveOnHover ?? false,
    key: options?.key,
    removed: false,
    variant: options?.variant ?? 'default',
    onRemoved: options?.onRemoved,
    remove: () => {
      itemInstances.value[id]?.hide();
    },
  });

  toasts.value.push(toast);

  return toast;
}

provide(TOASTER_API, {
  create,
  info(content: string, options?: ToastOptions) {
    return create(content, { ...options, variant: 'info' });
  },
  success(content: string, options?: ToastOptions) {
    return create(content, { ...options, variant: 'success' });
  },
  warning(content: string, options?: ToastOptions) {
    return create(content, { ...options, variant: 'warning' });
  },
  danger(content: string, options?: ToastOptions) {
    return create(content, { ...options, variant: 'danger' });
  },
  removeAll,
});
</script>

<template>
  <Teleport v-if="toasts.length" to="body">
    <div :class="$style.list">
      <ObToastsItem
        v-for="toast in toasts"
        :ref="
          ((instance: InstanceType<typeof ObToastsItem>) => {
            if (instance) {
              itemInstances[toast.id] = instance;
            }
          }) as () => void // TODO: why it doesn't support function ref type?
        "
        :key="toast.id"
        :toast="toast"
        @after-hide="remove(toast.id)"
      />
    </div>
  </Teleport>
  <slot />
</template>

<style lang="scss" module>
@use '../../styles/shared';

.list {
  position: fixed;
  left: 50%;
  top: 24px;
  display: flex;
  flex-direction: column;
  max-width: 560px;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: shared.$z-index-toast;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
</style>
