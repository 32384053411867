import { ref, computed, unref, watch, onUnmounted } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useArrayFindIndex } from '@vueuse/core';
import type { MaybeRef } from '@vueuse/core';
import { useId } from '../use-id';

type StackItem<T = unknown> = { id: string; payload: T };
type Stack<T = unknown> = Ref<StackItem<T>[]>;

const stacks: Record<string, Stack> = {};

function add(name: string, id: string, payload?: unknown): void {
  remove(name, id);
  stacks[name].value?.push({ id, payload });
}

function remove(name: string, id: string): void {
  if (!stacks[name]) return;
  stacks[name].value = stacks[name].value.filter((item) => item.id !== id);
}

interface UseStackReturn<T = unknown> {
  stack: Stack<T>;
  add: () => void;
  remove: () => void;
  index: ComputedRef<number>;
  first: ComputedRef<boolean>;
  last: ComputedRef<boolean>;
  exists: ComputedRef<boolean>;
}

interface UseStackOptions<T = unknown> {
  payload?: MaybeRef<T>;
  active?: MaybeRef<boolean>;
}

export function useStack<T = unknown>(name: string, options?: UseStackOptions): UseStackReturn<T> {
  const id = useId();

  let stack: Stack<T>;

  if (!stacks[name]) {
    stack = ref([]);
    stacks[name] = stack;
  } else {
    stack = stacks[name] as Stack<T>;
  }

  const index = useArrayFindIndex(stack, (item) => item.id === id);
  const exists = computed(() => index.value > -1);
  const first = computed(() => index.value === 0);
  const last = computed(() => exists.value && index.value === stack.value.length - 1);

  onUnmounted(() => remove(name, id));

  watch(
    () => unref(options?.active),
    (val, oldVal) => {
      if (val === oldVal) return;

      if (val) {
        add(name, id, options?.payload);
        return;
      }

      remove(name, id);
    },
    { immediate: true },
  );

  return {
    stack,
    add: () => add(name, id, options?.payload),
    remove: () => remove(name, id),
    index,
    exists,
    first,
    last,
  };
}
