import { shallowRef } from 'vue';
import { UnleashClient } from 'unleash-proxy-client';
import type { IConfig } from 'unleash-proxy-client';

export const unleashClient = shallowRef<UnleashClient>();

export function createUnleashClient(config: IConfig): UnleashClient {
  const client = new UnleashClient(config);
  unleashClient.value = client;
  return client;
}

export * from '@unleash/proxy-client-vue';
