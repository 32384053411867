import toString from 'lodash/toString';
import isNil from 'lodash/isNil';

export function pxOrValue(value: number | string | null | undefined): string {
  if (isNil(value)) return '';

  if (value === 0) return toString(value);

  if (typeof value === 'number') return `${value}px`;

  const numValue = parseFloat(value);

  if (value === toString(numValue)) {
    return `${value}px`;
  }

  return value;
}
