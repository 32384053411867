import {
  defineComponent,
  ref,
  Teleport,
  Transition,
  h,
  toRefs,
  computed,
  watch,
  onBeforeUnmount,
  cloneVNode,
  nextTick,
} from 'vue';
import type { PropType } from 'vue';
import { unrefElement } from '@vueuse/core';
import { useFloating, offset, flip, size, autoUpdate, shift } from '@floating-ui/vue';
import type { Placement, Middleware } from '@floating-ui/vue';
import isFinite from 'lodash/isFinite';
import isUndefined from 'lodash/isUndefined';
import throttle from 'lodash/throttle';
import isNil from 'lodash/isNil';
import { useParentZIndexStack, usePopover } from '../../composables';
import { pxOrValue } from '../../utils';
import './style.scss';

// TODO: focus directive in popover should have priority
// TODO: Home/End keydown
// TODO: A11y, aria-attributes

export default defineComponent({
  inheritAttrs: false,
  props: {
    activateOnArrowKeydown: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    allowTab: {
      type: Boolean,
      default: false,
    },
    deactivateOnPopoverClick: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
    maxHeight: {
      type: [Number, String],
      default: undefined,
    },
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
    minHeight: {
      type: [Number, String],
      default: undefined,
    },
    minWidth: {
      type: [Number, String],
      default: undefined,
    },
    offset: {
      type: Number,
      default: 4,
    },
    padding: {
      type: Number,
      default: 12,
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'bottom',
    },
    toggleOnHostClick: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
  },
  emits: ['update:active', 'activate', 'deactivate', 'activated', 'deactivated'],
  setup(props, { emit, slots }) {
    const hostRef = ref();
    const floatingRef = ref();

    const visible = ref(false);

    /* Popover */

    const { active, activate, deactivate, toggle, focusHost } = usePopover(
      hostRef,
      floatingRef,
      props,
    );

    /* Floating */

    const middleware = computed<Middleware[]>(() => {
      const result = [
        size({
          padding: props.padding,
          apply({ elements, availableWidth, availableHeight }) {
            let width;
            let minWidth;
            let maxWidth;

            if (!isUndefined(props.width) && isFinite(props.width)) {
              width = pxOrValue(props.width);
            } else {
              minWidth = !isNil(props.minWidth) ? pxOrValue(props.minWidth) : null;
              maxWidth = pxOrValue(!isNil(props.maxWidth) ? props.maxWidth : availableWidth);
            }

            let height;
            let minHeight;
            let maxHeight;

            if (!isUndefined(props.height) && isFinite(props.height)) {
              height = pxOrValue(props.height);
            } else {
              minHeight = !isNil(props.minHeight) ? pxOrValue(props.minHeight) : null;
              maxHeight = pxOrValue(!isNil(props.maxHeight) ? props.maxHeight : availableHeight);
            }

            Object.assign(elements.floating.style, {
              width,
              maxWidth,
              minWidth,
              height,
              minHeight,
              maxHeight,
            });
          },
        }),
        offset(() => props.offset),
      ];

      if (props.flip) {
        result.push(
          flip({
            padding: props.padding,
          }),
        );
      }

      result.push(
        shift({
          padding: props.padding,
        }),
      );

      return result;
    });

    const options = toRefs(props);

    const {
      x,
      y,
      strategy,
      placement,
      update: updateFloating,
    } = useFloating(hostRef, floatingRef, {
      placement: options.placement,
      middleware,
    });

    watch([() => props.offset, () => props.padding], () => {
      if (!active.value) return;

      updateFloating();
    });

    watch(visible, (value, oldValue) => {
      if (value === oldValue) return;
      emit(value ? 'activated' : 'deactivated');
    });

    let stopAutoUpdate: (() => void) | null;

    watch(visible, (value) => {
      if (stopAutoUpdate) {
        stopAutoUpdate();
        stopAutoUpdate = null;
      }

      if (!value) return;

      nextTick(() => {
        if (!active.value) return;

        const host = unrefElement(hostRef);
        const floating = unrefElement(floatingRef);

        if (!host || !floating) return;

        stopAutoUpdate = autoUpdate(
          host,
          floating,
          throttle(() => updateFloating(), 16),
          {
            ancestorResize: true,
            elementResize: true,
            ancestorScroll: true,
          },
        );
      });
    });

    onBeforeUnmount(() => {
      if (stopAutoUpdate) {
        stopAutoUpdate();
      }
    });

    /* ZIndex */

    const { zIndex } = useParentZIndexStack(visible);

    /* Model */

    watch(active, (value, oldValue) => {
      if (value === oldValue) return;
      emit('update:active', value);
      emit(value ? 'activate' : 'deactivate');
    });

    watch(
      () => props.active,
      (newValue, oldValue) => {
        if (newValue === oldValue) return;
        active.value = newValue;
      },
      {
        immediate: true,
      },
    );

    /* Render */

    const floatingRefStyle = computed(() => {
      return {
        position: strategy.value,
        top: `${Math.round(y.value || 0)}px`,
        left: `${Math.round(x.value || 0)}px`,
        zIndex: visible.value ? zIndex.value : null,
      };
    });

    return () => {
      const sharedSlotScope = {
        active: active.value,
        activate,
        // It allows to bind deactivate() on button inside popover and not loose focus
        deactivate: () => {
          deactivate();
          focusHost();
        },
        toggle: () => {
          toggle();

          if (!active.value) {
            focusHost();
          }
        },
      };

      const hostChildren = slots.host ? slots.host(sharedSlotScope) : [];

      if (hostChildren.length > 1) {
        return console.error('[Onebeat UI]: The Popover component only expects one host child.');
      }

      let host;

      if (hostChildren.length) {
        host = h(cloneVNode(hostChildren[0], {}), {
          ref: hostRef,
        });
      }

      return [
        host,
        h(Teleport, { to: 'body' }, [
          h(
            Transition,
            {
              name: '_transition',
              mode: 'in-out',
              onAfterLeave: () => {
                visible.value = false;
              },
              onBeforeEnter: () => {
                visible.value = true;
              },
            },
            () =>
              active.value
                ? h(
                    'div',
                    {
                      class: [
                        'ob-popover',
                        placement.value ? `_placement-${placement.value.split('-')[0]}` : undefined,
                      ],
                      ref: floatingRef,
                      style: floatingRefStyle.value,
                      tabindex: -1,
                    },
                    slots.default && slots.default(sharedSlotScope),
                  )
                : undefined,
          ),
        ]),
      ];
    };
  },
});
