import { defineStore } from 'pinia';
import {
  getDecimalSeparator,
  getThousandSeparator,
  getLocaleTextDirection,
} from '../services/i18n';

interface State {
  locale: string;
  decimalSeparator: string;
  thousandSeparator: string;
  textDirection: 'ltr' | 'rtl';
}

export const useI18nStore = defineStore('i18n', {
  state: (): State => ({
    locale: '',
    textDirection: 'ltr',
    decimalSeparator: '',
    thousandSeparator: '',
  }),
  actions: {
    setLocale(locale: string) {
      this.locale = locale;
      this.decimalSeparator = getDecimalSeparator(locale);
      this.thousandSeparator = getThousandSeparator(locale);
      this.textDirection = getLocaleTextDirection(locale);
    },
  },
  persist: true,
});
