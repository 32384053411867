import { h } from 'vue';
import type { SVGAttributes, FunctionalComponent } from 'vue';
import { SizeS, SizeM, SizeL } from '../../shared/types';
import { ObSvgIcon } from './';

type SvgChild = [string, Record<string, string>];

type Props = {
  size?: SizeS | SizeM | SizeL | 'auto' | string;
  color?: 'currentColor' | string;
  title?: string;
};

export type CommonSvgAttributes = Partial<SVGAttributes> & { viewBox: string };

export function createIconComponent(
  colorAttribute: 'fill' | 'stroke',
  svgAttributes: CommonSvgAttributes,
  children: SvgChild[],
): FunctionalComponent<Props> {
  return (props, { attrs }) =>
    h(
      ObSvgIcon,
      {
        ...attrs,
        ...svgAttributes,
        [colorAttribute]: props.color ?? 'currentColor',
        size: props.size,
        title: props.title,
      },
      () => children.map((child) => h(...child)),
    );
}
