import {
  ref,
  watch,
  onBeforeUnmount,
  onBeforeMount,
  onMounted,
  onUnmounted,
  provide,
  inject,
  computed,
} from 'vue';
import type { Ref, ComputedRef, InjectionKey } from 'vue';
import { unrefElement, tryOnMounted, useActiveElement } from '@vueuse/core';
import { createFocusTrap } from 'focus-trap';
import type { Options as TrapOptions } from 'focus-trap';
import { getNativeFocused } from '../../utils';

// TODO: it should be exported from focus-trap, make issue
type TrapActivateOptions = Pick<TrapOptions, 'onActivate' | 'onPostActivate' | 'checkCanFocusTrap'>;
type TrapDeactivateOptions = Pick<
  TrapOptions,
  'onDeactivate' | 'onPostDeactivate' | 'checkCanReturnFocus'
> & {
  returnFocus?: boolean;
};

interface UseFocusZoneOptions {
  autoInjectParent?: boolean;
  parentZoneId?: symbol;
  trapOptions?: TrapOptions;
  withTrap?: boolean;
}

interface UseFocusZoneReturn {
  id: symbol;
  active: ComputedRef<boolean>;
  activateTrap: (options?: TrapActivateOptions) => void;
  deactivateTrap: (options?: TrapDeactivateOptions) => void;
  pauseTrap: () => void;
  unpauseTrap: () => void;
  trapActive: ComputedRef<boolean>;
  trapPaused: ComputedRef<boolean>;
}

interface Zone {
  root: Ref<HTMLElement | undefined>;
  addChild: (id: symbol) => void;
  removeChild: (id: symbol) => void;
  isActive: () => boolean;
  hasElement: (element: Element) => boolean;
  updateTrapContainers: () => void;
  getTrapContainers: () => HTMLElement[];
}

const zonesMap = new Map<symbol, Zone>();

export const FOCUS_ZONE_INJECTION_KEY: InjectionKey<symbol> = Symbol('focus zone id');

export function useFocusZone(
  root: Ref<HTMLElement | undefined>,
  options: UseFocusZoneOptions = {},
): UseFocusZoneReturn {
  const id = Symbol('zone id');
  const activeElement = useActiveElement();
  const active = ref<boolean>(false);
  const activeSelf = ref<boolean>(false);
  const children = ref<symbol[]>([]);

  provide(FOCUS_ZONE_INJECTION_KEY, id);

  const { autoInjectParent = true } = options;

  const parentZoneId =
    autoInjectParent && !options.parentZoneId
      ? inject(FOCUS_ZONE_INJECTION_KEY, undefined)
      : options.parentZoneId;

  function addChild(zoneId: symbol) {
    if (children.value.some((item) => item === zoneId)) return;
    children.value.push(zoneId);
  }

  function removeChild(zoneId: symbol) {
    children.value = children.value.filter((item) => item !== zoneId);
  }

  function hasElement(element: Element) {
    const rootEl = unrefElement(root);

    if (!rootEl) return false;

    return element === rootEl || rootEl.contains(element);
  }

  function isActiveSelf() {
    const focusedEl = getNativeFocused();

    // TODO: it's temp solution to allow quasar portalled dropdowns inside popovers
    // - remove it
    // - think about recipe for such conslicts with focus zones

    const quasarPortals = document.querySelectorAll('.q-position-engine');
    if (
      Array.from(quasarPortals).some(
        (element) => focusedEl === element || element.contains(focusedEl),
      )
    ) {
      return true;
    }

    return !!focusedEl && hasElement(focusedEl);
  }

  function isActive() {
    if (isActiveSelf()) return true;
    return children.value.some((zoneId: symbol) => {
      const zone = zonesMap.get(zoneId);
      return zone ? zone.isActive() : false;
    });
  }

  function update() {
    activeSelf.value = isActiveSelf();
    active.value = isActive();
  }

  function getParentZone(): Zone | undefined {
    if (!parentZoneId) return undefined;

    return zonesMap.get(parentZoneId);
  }

  const trap = options.withTrap
    ? createFocusTrap([], {
        escapeDeactivates: false,
        allowOutsideClick: true,
        preventScroll: true,
        ...options.trapOptions,
      })
    : null;
  const trapActive = ref(false);
  const trapPaused = ref(false);

  function getTrapContainers(): HTMLElement[] {
    const childrenTrapContainers = children.value.reduce<HTMLElement[]>((acc, id) => {
      const childZone = zonesMap.get(id);

      if (childZone) {
        return acc.concat(childZone.getTrapContainers());
      }

      return acc;
    }, []);
    const trapContainer = unrefElement(root.value);

    const result = [];

    if (trapContainer) {
      result.push(trapContainer);
    }

    result.push(...childrenTrapContainers);

    return result;
  }

  function updateTrapContainers() {
    if (trap) {
      trap.updateContainerElements(getTrapContainers());
    }

    const currentParent = getParentZone();

    if (currentParent) {
      currentParent.updateTrapContainers();
    }
  }

  function activateTrap(options?: TrapActivateOptions) {
    if (!trap) return; // TODO: error?

    tryOnMounted(() => {
      trap.activate(options);
      trapActive.value = true;
    });
  }

  function deactivateTrap(options?: TrapDeactivateOptions) {
    if (!trap) return; // TODO: error?

    trap.deactivate(options);
    trapActive.value = false;
  }

  function pauseTrap() {
    if (!trap) return; // TODO: error?

    trap.pause();
    trapPaused.value = true;
  }

  function unpauseTrap() {
    if (!trap) return; // TODO: error?

    trap.unpause();
    trapPaused.value = false;
  }

  const zone: Zone = {
    root,
    addChild,
    removeChild,
    isActive,
    hasElement,
    getTrapContainers,
    updateTrapContainers,
  };

  onBeforeMount(() => {
    zonesMap.set(id, zone);

    const currentParent = getParentZone();

    if (currentParent) {
      currentParent.addChild(id);
    }
  });

  onMounted(() => {
    updateTrapContainers();
  });

  onBeforeUnmount(() => {
    zonesMap.delete(id);

    const currentParent = getParentZone();

    if (currentParent) {
      currentParent.removeChild(id);
    }
  });

  onUnmounted(() => {
    if (!trap) return;
    trap.deactivate();
  });

  watch(
    activeElement,
    (val, oldVal) => {
      if (val === oldVal) return;
      update();
    },
    { immediate: true },
  );

  watch(root, (val, oldVal) => {
    if (val === oldVal) return;

    update();
  });

  watch([root, children], () => {
    updateTrapContainers();
  });

  return {
    id,
    active: computed(() => active.value),
    activateTrap,
    deactivateTrap,
    pauseTrap,
    unpauseTrap,
    trapActive: computed(() => trapActive.value),
    trapPaused: computed(() => trapPaused.value),
  };
}
