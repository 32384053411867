import { defineStore } from 'pinia';

// TODO: Drop this when backend support is ready
export const useStoreTransferDemoStore = defineStore('store-transfer-demo', {
  state: () => ({
    approved_cluster_ids: [] as string[],
    rejected_cluster_ids: [] as string[],
  }),
  getters: {
    approvedClusters: (state) => {
      return state.approved_cluster_ids;
    },
    rejectedClusters: (state) => {
      return state.rejected_cluster_ids;
    },
  },
  actions: {
    async addClusterIdToApproved(id: string): Promise<void> {
      this.approvedClusters.push(id);
    },
    async addClusterIdToRejected(id: string): Promise<void> {
      this.rejectedClusters.push(id);
    },
  },
});
