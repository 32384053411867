import {
  createRouter as _createRouter,
  createWebHistory,
  isNavigationFailure,
  START_LOCATION,
  NavigationFailureType,
} from 'vue-router';
import type { Router } from 'vue-router';
import { useAppReady } from '../app';
import { useApi } from '../services/api';
import { routes } from './routes';
import { runGuards } from './guards';
import type { Guard } from './guards';

declare module 'vue-router' {
  interface RouteMeta {
    guards?: Array<Guard>;
  }
}

export interface RouterOptions {
  baseUrl: string;
}

export function createRouter(options: RouterOptions): Router {
  const router = _createRouter({
    routes,
    history: createWebHistory(options.baseUrl),
    linkActiveClass: '',
    linkExactActiveClass: '',
    async scrollBehavior(to, from, savedScrollPosition) {
      // TODO: find best approach for delaying scroll behavior
      // https://router.vuejs.org/guide/advanced/scroll-behavior.html#delaying-the-scroll
      if (savedScrollPosition) return savedScrollPosition;

      // Change route query without scroll jumping
      if (from.path === to.path) return undefined;

      if (to.hash) {
        return { el: to.hash };
      }

      return { left: 0, top: 0 };
    },
  });

  router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.href = to.fullPath;
    }
  });

  let redirectsCount = 0;
  let lastNavigationId;

  // We must wait until app is ready due to async authorization.
  // Only after it we can run auth guards.
  // Keycloak uses hash in redirect url to provide needed data and then removes hash.
  // But router initializes before Keycloak and keep initial hash value in url.
  // To solve this issues we delay initial router navigation until auth data is updated and the app is ready.
  // Then we add add guards and replace initial location with the actual (cleaned from hash).

  const { isReady: isAppReady } = useAppReady();

  const removeInitialNavigationGuard = router.beforeEach(async (to, from) => {
    if (from === START_LOCATION) {
      await isAppReady();
    }

    removeInitialNavigationGuard();

    router.beforeEach(async (to, from) => {
      const api = useApi();

      const navigationId = Date.now();
      lastNavigationId = navigationId;

      const result = (await runGuards(from, to, { api })) ?? true;

      // If navigation was cancelled, but guards return redirect - redirect will be done.
      // Prevent this behavior.
      // TODO: stop runGuards() execution in this case
      if (lastNavigationId !== navigationId) {
        return;
      }

      if (typeof result !== 'boolean') {
        redirectsCount++;
      }

      if (redirectsCount >= 10) {
        redirectsCount = 0;
        throw new Error('Redirect loop detected');
      }

      return result;
    });

    return `${window.location.pathname}${window.location.search}${window.location.hash}`;
  });

  router.afterEach((to, from, failure) => {
    if (isNavigationFailure(failure, NavigationFailureType.cancelled)) return;

    redirectsCount = 0;
  });

  return router;
}

export * from './guards';
