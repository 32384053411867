import { MeiliSearchService } from './meilisearch-service';
import type { MeilisearchTokenResponse } from './interfaces';

export class MeiliSearchGlobalService extends MeiliSearchService {
  protected async getToken(): Promise<string> {
    const { data } = await this.transport.post<MeilisearchTokenResponse>(
      '/v1/meilisearch/global_token',
      {},
    );

    return data.token;
  }
}
