import type { RouteRecordRaw } from 'vue-router';
import { authGuard, notAuthGuard } from '../guards';
import { unleashClient } from '../../services/unleash';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../../layouts/default-layout.vue'),
    meta: {
      guards: [authGuard],
    },
    children: [
      {
        path: '',
        redirect: '/inventory',
      },
      {
        path: '/replenishment',
        name: 'replenishment',
        component: () => import('../../views/replenishment/replenishment-index.vue'),
        redirect: '/replenishment/overview',
        children: [
          {
            path: 'overview',
            component: () => import('../../views/replenishment/replenishment-overview.vue'),
          },
          {
            path: 'store',
            component: () => import('../../views/replenishment/replenishment-store.vue'),
          },
          {
            path: 'product',
            component: () => import('../../views/replenishment/replenishment-product.vue'),
          },
        ],
      },
      {
        path: '/analytics',
        component: () => import('../../views/analytics/analytics-superset.vue'),
      },
      {
        path: '/inventory',
        component: () => import('../../views/inventory/inventory-index.vue'),
        redirect: '/inventory/location',
        children: [
          {
            path: 'location',
            component: () => import('../../views/inventory/inventory-location.vue'),
          },
          {
            path: 'sku',
            component: () => import('../../views/inventory/inventory-sku.vue'),
          },
          {
            path: 'sku-location',
            component: () => import('../../views/inventory/inventory-sku-location.vue'),
          },
        ],
      },
      {
        path: '/special-events',
        name: 'special_events',
        component: () => import('../../views/special-events/special-events.vue'),
        meta: {
          guards: [
            () => {
              if (__DEV__ || unleashClient.value?.isEnabled('specialEvents')) return true;
              return '/';
            },
          ],
        },
      },
      {
        path: '/store-transfer',
        name: 'store-transfer',
        component: () => import('../../views/store-transfer/store-transfer-index.vue'),
        redirect: '/store-transfer/trips',
        children: [
          {
            path: 'trips',
            component: () => import('../../views/store-transfer/store-transfer-trips.vue'),
          },
          {
            path: 'products',
            component: () => import('../../views/store-transfer/store-transfer-products.vue'),
          },
        ],
        meta: {
          guards: [
            () => {
              if (__DEV__ || unleashClient.value?.isEnabled('storeTransfer')) return true;
              return '/';
            },
          ],
        },
      },
      {
        path: '/store-transfer-history',
        name: 'store-transfer-history',
        component: () => import('../../views/store-transfer/store-transfer-history-index.vue'),
        redirect: { name: 'history-trips' },
        children: [
          {
            path: 'history-trips',
            name: 'history-trips',
            component: () => import('../../views/store-transfer/store-transfer-history-trips.vue'),
          },
        ],
        meta: {
          guards: [
            () => {
              if (__DEV__ || unleashClient.value?.isEnabled('storeTransfer')) return true;
              return '/';
            },
          ],
        },
      },
      {
        path: '/store-transfer/product-charts',
        name: 'store-transfer-product-charts',
        component: () => import('../../views/store-transfer/store-transfer-product-charts.vue'),
        meta: {
          guards: [
            () => {
              if (__DEV__ || unleashClient.value?.isEnabled('storeTransfer')) return true;
              return '/';
            },
          ],
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      guards: [notAuthGuard],
    },
    component: () => import('../../views/auth-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../../views/not-found.vue'),
  },
];
