import type { InjectionKey } from 'vue';
import type { Status } from '../../shared/types';

type ToastVariant = Status | 'default';

interface ToastAction {
  label: string;
  callback: (toast: Toast) => void;
}

export interface Toast {
  actions: ToastAction[];
  closable: boolean;
  content: string;
  duration: number;
  readonly id: string;
  keepAliveOnHover: boolean;
  key?: string;
  removed: boolean;
  variant: ToastVariant;
  readonly remove: () => void;
  onRemoved?: (toast: Toast) => void;
}

export interface ToastOptions {
  actions?: ToastAction[];
  closable?: boolean;
  duration?: number;
  key?: string;
  keepAliveOnHover?: boolean;
  variant?: ToastVariant;
  onRemoved?: (toast: Toast) => void;
}

export interface ToasterApi {
  create: (content: string, options?: ToastOptions) => Toast;
  info: (content: string, options?: ToastOptions) => Toast;
  success: (content: string, options?: ToastOptions) => Toast;
  warning: (content: string, options?: ToastOptions) => Toast;
  danger: (content: string, options?: ToastOptions) => Toast;
  removeAll: () => void;
}

export const TOASTER_API: InjectionKey<ToasterApi> = Symbol(__DEV__ ? 'Toaster API' : '');
