import type { ProductImage } from './shared';

export type StoreTransferKpis = {
  expected_sales_increase: number;
  trips: number;
  products: number;
  stores: number;
  units: number;
};

export type ActualApprovedPercentage = {
  percentage?: number;
  actual: number;
  approved: number;
};

export type StoreTransferHistoryKpis = {
  expected_sales_increase: ActualApprovedPercentage;
  trips: ActualApprovedPercentage;
  stores: ActualApprovedPercentage;
  products: ActualApprovedPercentage;
  units: ActualApprovedPercentage;
};

export enum ClusterStates {
  REJECTED = 'rejected',
  APPROVED = 'approved',
  UNDETERMINED = 'undetermined',
}

export type ClusterState =
  | ClusterStates.REJECTED
  | ClusterStates.APPROVED
  | ClusterStates.UNDETERMINED;

export type ClusterData = {
  id: string;
  name: string;
  expected_sales_increase: number;
  total_trips: number;
  total_stores: number;
  total_units: number;
};

export type TripCluster = ClusterData & {
  state: ClusterState;
};

export type STSkuData = {
  external_sku_id: string;
  category: string;
  product_name: string;
  size: string;
  units: number;
};

export type TripData = {
  id: string;
  from: string;
  to: string;
  cluster_id: string;
  total_products: number;
  trip_cost: number;
  skus?: STSkuData[];
};

export type Trip = TripData & {
  units: number;
  expected_sales_increase: number;
};

export type TripHistory = TripData & {
  units: ActualApprovedPercentage;
  expected_sales_increase: ActualApprovedPercentage;
};

export type StoreTransferProduct = {
  id: string;
  name: string;
  images: ProductImage[];
  units: number;
  expected_sales_rate: number;
};

//TODO: Check if stores is part of each product or calculated when needed (is this necessary?)
export type StoreTransferProductDetails = {
  id: string;
  name: string;
  description: string;
  images: ProductImage[];
  units: number;
  expected_sales_rate: number;
  stores: number;
};

export type StoreTransferProductSizeData = {
  do_not_move: number;
  recieve: number;
  send: number;
};

export type StoreTransferProductLocationChartData = {
  location_name: string;
  sale_rate: number;
  data: Array<{ size: string; sizeData: StoreTransferProductSizeData }>;
};

export type StoreTransferProductChartCluster = ClusterData & {
  chartsData: StoreTransferProductLocationChartData[];
};
