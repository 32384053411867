import { defineStore } from 'pinia';
import { useApi } from '../services/api';

interface State {
  indexes: {
    Inventory: string;
    Location: string;
    Replenishment: string;
    Sku: string;
    SpecialEvent: string;
  };
}

export const useMeilisearchStore = defineStore('meilisearch', {
  state: (): State => ({
    indexes: {
      Location: 'Location',
      Sku: 'Sku',
      Inventory: 'Inventory',
      Replenishment: 'Replenishment',
      SpecialEvent: 'SpecialEvent',
    },
  }),
  actions: {
    async fetchIndexes(): Promise<void> {
      const api = useApi();

      const { data } = await api.getMeilisearchIndexes();
      this.indexes = data.data;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
