import { createApp as createVueApp } from 'vue';
import type { App as VueApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Quasar } from 'quasar';
import { createHead } from '@vueuse/head';
import { createRouter } from '../router';
import { createStore } from '../store';
import { createApi } from '../services/api';
import { createI18n } from '../services/i18n';
import { createHeap } from '../services/heap';
import { createUnleashClient, plugin as unleashPlugin } from '../services/unleash';
import { config } from './config';
import App from './app.vue';

const router = createRouter({
  baseUrl: config.baseUrl,
});

export function createApp(): VueApp {
  const app = createVueApp(App)
    .use(
      createApi({
        baseUrl: config.apiUrl,
        keycloak: config.keycloak,
        meilisearchHost: config.meilisearch.host,
      }),
    )
    .use(createStore())
    .use(router)
    .use(createI18n())
    .use(
      createHead({
        titleTemplate: (value) => `${value ? value + ' | ' : ''}Onebeat App`,
      }),
    );

  app.use(Quasar, {});

  if (config.unleash.enabled) {
    const client = createUnleashClient({
      url: config.unleash.url,
      clientKey: config.unleash.clientKey,
      appName: config.unleash.appName,
      refreshInterval: config.unleash.refreshInterval,
    });

    app.use(unleashPlugin, {
      unleashClient: client,
    });
  }

  if (config.heap.enabled) {
    app.use(createHeap(config.heap.appId));
  }

  if (config.sentry.enabled) {
    Sentry.init({
      app,
      release: config.sentry.release,
      environment: config.sentry.environment,
      dsn: config.sentry.dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', /^\//],
        }),
      ],
      tracesSampleRate: config.sentry.environment === 'production' ? 0.25 : 1,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      logErrors: config.sentry.environment !== 'production',
    });
  }

  app.config.errorHandler = (error) => {
    if (config.sentry.enabled) {
      Sentry.captureException(error);
    }

    console.error(error);
  };

  return app;
}
