import { createPinia } from 'pinia';
import type { Pinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

export function createStore(): Pinia {
  const pinia = createPinia();

  pinia.use(
    createPersistedState({
      key: (id) => `onebeat-app:${id}`,
      debug: __DEV__,
    }),
  );

  return pinia;
}

export * from './data';
export * from './meilisearch';
export * from './app-settings';
export * from './store-transfer-demo';
export * from './user';
export * from './i18n';
