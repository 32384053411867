import { PaginationQuery } from './interfaces/shared';
import { AxiosTransport } from './transport';
import { KeycloakAuth } from './auth';
import type { Transport } from './transport';
import {
  ReplenishmentKpis,
  PaginatedResponse,
  Location,
  Inventory,
  InventoryDetails,
  ProductDetails,
  Product,
  ProductWithDetails,
  ReplenishmentPickingList,
  ReplenishmentOverview,
  InsufficientReplenishmentSummary,
  InsufficientReplenishmentOverviewItem,
  ProductSkuDetails,
  SpecialEventsKpis,
  InventorySkuChartOverviewItem,
  InventorySkuChartSummary,
  Upload,
  LocationBasedAnalytics,
  LocationBasedAnalyticsQuery,
  TimeBasedAnalytics,
  TimeBasedAnalyticsQuery,
  LocationKpis,
  SkuKpis,
  InventoryKpis,
  AvoidReplenishmentPayload,
  AvoidReplenishmentResponse,
  MeilisearchIndexesResponse,
  SpecialEventsDeleteResponse,
  SpecialEventsDeleteQuery,
  SpecialEventsCreateEditPayload,
  StoreTransferKpis,
  TripCluster,
  Trip,
  TripHistory,
  ClusterStates,
  StoreTransferProduct,
  StoreTransferProductChartCluster,
  StoreTransferHistoryKpis,
  SpecialEventCategory,
  SpecialEventProduct,
  SpecialEventLocation,
  SpecialEventSku,
  ProductSkuDetailsInLocation,
  MeilisearchSpecialEvent,
} from './interfaces';
import { MeiliSearchService } from './meilisearch-service';
import { MeiliSearchGlobalService } from './meilisearch-global-service';

export interface ApiServiceOptions {
  transport?: Transport;
  baseUrl: string;
  meilisearchHost: string;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
    enableLogging?: boolean;
    silentCheckSsoRedirectUri: string;
  };
}

type SupersetTokenResponse = {
  token: string;
};

export class ApiService {
  private readonly transport: Transport;
  readonly auth: KeycloakAuth;
  readonly meilisearch: MeiliSearchService;
  readonly meilisearchGlobal: MeiliSearchGlobalService;

  constructor(options: ApiServiceOptions) {
    this.transport =
      options?.transport ||
      new AxiosTransport({
        baseUrl: options.baseUrl,
        getAccessToken: () => {
          return this.auth.getToken();
        },
        authErrorHandler: () => {
          this.auth.logout();
        },
      });

    this.auth = new KeycloakAuth({ ...options.keycloak, transport: this.transport });
    this.meilisearch = new MeiliSearchService({
      auth: this.auth,
      transport: this.transport,
      host: options.meilisearchHost,
    });
    this.meilisearchGlobal = new MeiliSearchGlobalService({
      auth: this.auth,
      transport: this.transport,
      host: options.meilisearchHost,
    });
  }

  getSupersetGuestToken() {
    return this.transport.post<SupersetTokenResponse>('v1/supersets', {});
  }

  getSupersetDashboards() {
    return this.transport.get<{ data: Array<{ uuid: string; name: string; description: string }> }>(
      'v1/supersets/dashboards',
    );
  }

  getMeilisearchIndexes() {
    return this.transport.get<{ data: MeilisearchIndexesResponse }>('/v1/meilisearch/indexes');
  }

  getSkuKpis() {
    return this.transport.get<SkuKpis>('/v1/skus/kpis');
  }

  getLocationKpis() {
    return this.transport.get<LocationKpis>('/v1/locations/kpis');
  }

  getInventoryKpis() {
    return this.transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  getReplenishmentKpis() {
    return this.transport.get<ReplenishmentKpis>('/v1/replenishments/kpis');
  }

  getSpecialEventsKpi() {
    return this.transport.get<SpecialEventsKpis>('/v1/special_events/kpis');
  }

  getProducts(query?: PaginationQuery & { external_id?: string }) {
    return this.transport.get<PaginatedResponse<Product>>('/v1/products', {
      query: { ...query },
    });
  }

  getProduct(id: string) {
    return this.transport.get<{ data: ProductWithDetails }>(`/v1/products/${id}`);
  }

  getProductInventoryIdsListedByLocation(productId: string) {
    return this.transport.get<Record<string, string[]>>(
      '/v1/products/inventories_list_by_location',
      {
        query: { id: productId },
      },
    );
  }

  getLocation(id: string) {
    return this.transport.get<Location>(`/v1/locations/${id}`);
  }

  getInventory(id: string) {
    return this.transport.get<Inventory>(`/v1/inventories/${id}`);
  }

  getReplenishmentProductsByLocation(
    locationId: string,
    query?: PaginationQuery & { external_id?: string },
  ) {
    return this.transport.get<PaginatedResponse<Product>>('/v1/replenishments/products_by_store', {
      query: { location_id: locationId, ...query },
    });
  }

  getProductsDetails(productIds: string[]) {
    return this.transport.get<Record<string, ProductDetails>>('/v1/products/details', {
      query: { ids: productIds },
    });
  }

  getProductSkuDetails(productId: string) {
    return this.transport.get<Record<string, ProductSkuDetails>>('/v1/products/sku_details', {
      query: { id: productId },
    });
  }

  getProductSkuDetailsInLocation(productId: string, locationId: string) {
    return this.transport.get<{ data: ProductSkuDetailsInLocation[] }>(
      `v1/products/sku_details_by_store/${productId}/${locationId}`,
    );
  }

  getInventoryDetails(id: string) {
    return this.transport.get<InventoryDetails>('/v1/inventories/details', {
      query: { id },
    });
  }

  getReplenishmentPickingList() {
    return this.transport.post<ReplenishmentPickingList>(
      '/v1/replenishments/export_picking_list',
      {},
    );
  }

  getReplenishmentOverview() {
    return this.transport.get<ReplenishmentOverview>('/v1/replenishments/overview');
  }

  getInsufficientReplenishmentSummary(inventoryId: string) {
    return this.transport.get<{ data: InsufficientReplenishmentSummary }>(
      `/v1/replenishments/insufficient_replenishment/card/${inventoryId}`,
    );
  }

  getInsufficientReplenishmentOverview(inventoryId: string) {
    return this.transport.get<InsufficientReplenishmentOverviewItem[]>(
      `/v1/replenishments/insufficient_replenishment/overview/${inventoryId}`,
    );
  }

  getInventorySkuChartSummary(inventoryId: string) {
    return this.transport.get<InventorySkuChartSummary>(
      `/v1/inventories/sku_chart/card/${inventoryId}`,
    );
  }

  getInventorySkuChartOverview(
    inventoryId: string,
    query?: { start_date: string; end_date: string },
  ) {
    return this.transport.get<InventorySkuChartOverviewItem[]>(
      `/v1/inventories/sku_chart/overview/${inventoryId}`,
      {
        query,
      },
    );
  }

  getUploads(query?: PaginationQuery) {
    return this.transport.get<Upload[]>('/v1/uploads', {
      query,
    });
  }

  getLocationBasedAnalytics(query?: LocationBasedAnalyticsQuery) {
    return this.transport.get<{ data: LocationBasedAnalytics }>(
      '/v1/analytics/locations_overview',
      {
        query,
      },
    );
  }

  getTimeBasedAnalytics(query?: TimeBasedAnalyticsQuery) {
    return this.transport.get<{ data: TimeBasedAnalytics }>('/v1/analytics/time_based_overview', {
      query,
    });
  }

  avoidReplenishment(payload: AvoidReplenishmentPayload) {
    const { type, ids } = payload;
    return this.transport.put<AvoidReplenishmentResponse>(
      `/v1/${type}/avoid_replenishment`,
      { ids: JSON.stringify(ids) }, // TODO: set normal array when backend will be fixed
    );
  }

  createSpecialEvent(payload: SpecialEventsCreateEditPayload) {
    return this.transport.post<MeilisearchSpecialEvent>('v1/special_events', payload);
  }

  updateSpecialEvent(eventId: string, payload: SpecialEventsCreateEditPayload) {
    return this.transport.put<MeilisearchSpecialEvent>(`v1/special_events/${eventId}`, payload);
  }

  deleteSpecialEvents(query: SpecialEventsDeleteQuery) {
    return this.transport.delete<SpecialEventsDeleteResponse>('v1/special_events', {
      query,
    });
  }

  getStoreTransferKpis() {
    //TODO: Uncomment this and drop the fake kpis obj when backend is ready
    // return this.transport.get<StoreTransferKpis>('/v1/store_transfer/kpis');

    const kpis: StoreTransferKpis = {
      expected_sales_increase: 108000,
      trips: 37,
      stores: 27,
      products: 327,
      units: 1422,
    };

    return { data: kpis };
  }

  getStoreTransferHistoryKpis() {
    //TODO: Uncomment this and drop the fake kpis obj when backend is ready
    // return this.transport.get<StoreTransferKpis>('/v1/store_transfer/history_kpis');

    const historyKpis: StoreTransferHistoryKpis = {
      expected_sales_increase: { percentage: 87, actual: 54278, approved: 62167 },
      trips: { percentage: 88, actual: 14, approved: 16 },
      stores: { percentage: 82, actual: 9, approved: 11 },
      products: { percentage: 88, actual: 74, approved: 84 },
      units: { percentage: 85, actual: 269, approved: 315 },
    };

    return { data: historyKpis };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTripClusters(query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake clusters obj when backend is ready
    // return this.transport.get<PaginatedResponse<TripCluster>>('/v1/store_transfer/trip_clusters', {
    //   query: { ...query },
    // });
    const data: TripCluster[] = [
      {
        id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        name: 'South West',
        expected_sales_increase: 62167,
        total_trips: 16,
        total_stores: 11,
        total_units: 840,
        state: ClusterStates.UNDETERMINED,
      },
      {
        id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        name: 'North East',
        expected_sales_increase: 22705,
        total_trips: 10,
        total_stores: 8,
        total_units: 276,
        state: ClusterStates.UNDETERMINED,
      },
      {
        id: '13c4f8dc-f57d-488b-884d-838035502a76',
        name: 'South East',
        expected_sales_increase: 23065,
        total_trips: 11,
        total_stores: 8,
        total_units: 306,
        state: ClusterStates.UNDETERMINED,
      },
    ];

    return { data, meta: { page: 1, per_page: 25, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTripsByClusterId(clusterId: string, query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake trips obj when backend is ready
    // return this.transport.get<PaginatedResponse<Trip>>('/v1/store_transfer/trips_by_cluster', {
    //   query: { cluster_id: clusterId, ...query },
    // });
    const trips: Trip[] = [
      // South West
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'f0dd1054-5c3d-4156-8c4e-9da89915b081',
        from: 'Dallas Conch Republic Market',
        to: 'Western Emporium',
        units: 25,
        trip_cost: 125,
        expected_sales_increase: 1525,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'c73ba739-9cc1-40e8-9013-0682c7322cd4',
        from: 'San Antonio valley horizon Market',
        to: 'Southern Roots Farm and Market',
        units: 74,
        trip_cost: 222,
        expected_sales_increase: 6364,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '887a17f9-e107-4fa5-99cd-a44205754bb8',
        from: 'Golden hill  Market',
        to: 'Houston Space City Market',
        units: 48,
        trip_cost: 288,
        expected_sales_increase: 3648,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'fc629650-771a-43ae-8ea7-129511b44672',
        from: 'Oklahoma University Outfitter',
        to: 'Pacific Avenue Store',
        units: 31,
        trip_cost: 155,
        expected_sales_increase: 2542,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'a39cbb87-9c98-4c1a-a077-4028c56972fc',
        from: 'Western Emporium',
        to: 'Desert Dreams Gift Shop',
        units: 63,
        trip_cost: 252,
        expected_sales_increase: 5670,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '6ae47ef1-d301-4328-8376-bf44a94b2ca3',
        from: 'Southern Roots Farm and Market',
        to: 'San Francisco Conch Boutique',
        units: 53,
        trip_cost: 318,
        expected_sales_increase: 3922,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'b9bf8a48-29db-4e35-ab38-134658d091fa',
        from: 'Houston Space City Market',
        to: 'Dallas Conch Republic Market',
        units: 85,
        trip_cost: 340,
        expected_sales_increase: 6375,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '3d6367c3-d07b-4a7e-9723-fb23ee274330',
        from: 'Pacific Avenue Store',
        to: 'San Antonio valley horizon Market',
        units: 65,
        trip_cost: 390,
        expected_sales_increase: 4030,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '93a755e4-4b00-4e06-baf8-835dd507facd',
        from: 'Desert Dreams Gift Shop',
        to: 'Golden hill  Market',
        units: 46,
        trip_cost: 184,
        expected_sales_increase: 2576,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'bc3696f4-7e47-486c-96e8-a4c01368f9dc',
        from: 'San Francisco Conch Boutique',
        to: 'Oklahoma University Outfitter',
        units: 54,
        trip_cost: 324,
        expected_sales_increase: 4158,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '0236d16f-25bd-4cd3-9a05-2ad8b164b497',
        from: 'Austin Lone Star Market',
        to: 'San Antonio valley horizon Market',
        units: 29,
        trip_cost: 87,
        expected_sales_increase: 2291,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'bde0b0fc-8de6-4405-8a55-bb2fedd56ba9',
        from: 'Dallas Conch Republic Market',
        to: 'Oklahoma University Outfitter',
        units: 32,
        trip_cost: 224,
        expected_sales_increase: 2592,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'a734ef13-6ec6-435d-82bf-cd48bd47541f',
        from: 'San Antonio valley horizon Market',
        to: 'Houston Space City Market',
        units: 70,
        trip_cost: 490,
        expected_sales_increase: 4690,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '2212648e-8c4b-4ec2-83f3-fcde6dd35524',
        from: 'Golden hill Market',
        to: 'Austin Lone Star Market',
        units: 51,
        trip_cost: 306,
        expected_sales_increase: 2958,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '4bd04b99-2911-45a2-b2bc-b2632dd0ca24',
        from: 'Oklahoma University Outfitter',
        to: 'Golden hill  Market',
        units: 42,
        trip_cost: 168,
        expected_sales_increase: 3570,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '6fed555b-1444-43ab-a0e5-16e4e7738dba',
        from: 'Western Emporium',
        to: 'San Antonio valley horizon Market',
        units: 72,
        trip_cost: 360,
        expected_sales_increase: 5256,
        total_products: 12,
      },
      // North East
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: 'de58c955-0ba8-4b36-9453-1e33062c97a7',
        from: 'New York City Market',
        to: 'Northwoods Lodge',
        units: 12,
        trip_cost: 36,
        expected_sales_increase: 900,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '1fe27dbd-4fd3-4098-a609-fd9cf2d205d4',
        from: 'Capital fashion Boutique',
        to: 'Independence mall',
        units: 33,
        trip_cost: 66,
        expected_sales_increase: 2508,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '4a650b7f-2561-44ad-943f-ace285670e70',
        from: 'Boston Chic Boutique',
        to: 'Detriot high state Boutique',
        units: 16,
        trip_cost: 64,
        expected_sales_increase: 1376,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '0a048815-d46a-4efb-830e-16b000356a01',
        from: 'Detriot high state Boutique',
        to: 'Boston Chic Boutique',
        units: 40,
        trip_cost: 280,
        expected_sales_increase: 3560,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '74e84a9a-f30b-404c-a594-217146abce6a',
        from: 'Independence mall',
        to: 'Capital fashion Boutique',
        units: 10,
        trip_cost: 50,
        expected_sales_increase: 760,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '65d57c25-15fe-4e82-b4be-23aad1557961',
        from: 'Philadelphia brotherly Charm Store',
        to: 'Capital fashion Boutique',
        units: 43,
        trip_cost: 172,
        expected_sales_increase: 3526,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '7a791a39-a442-471d-b0cb-84b2cfe5da79',
        from: 'Northwoods Lodge',
        to: 'New York City Market',
        units: 23,
        trip_cost: 161,
        expected_sales_increase: 1886,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '9863b884-3874-403f-ad7a-1837a3c26068',
        from: 'Northwoods Lodge',
        to: 'Boston Chic Boutique',
        units: 38,
        trip_cost: 152,
        expected_sales_increase: 3230,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: '0606d3a2-0956-43c3-88a0-d08effedaa18',
        from: 'Capital fashion Boutique',
        to: 'New York City Market',
        units: 26,
        trip_cost: 78,
        expected_sales_increase: 2054,
        total_products: 12,
      },
      {
        cluster_id: 'b59452f6-4221-4c8d-b37a-d6e4816ed137',
        id: 'b757ec1a-7352-4510-af6e-8cc97fa5eb67',
        from: 'New York City Market',
        to: 'Independence mall',
        units: 35,
        trip_cost: 140,
        expected_sales_increase: 2905,
        total_products: 12,
      },
      // South East
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: 'b5eac009-9eb8-4139-a522-df5e88a767a0',
        from: 'Orlando Magic Market',
        to: 'Tampa Bay Sun Store',
        units: 37,
        trip_cost: 148,
        expected_sales_increase: 2997,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: '005d630d-40a3-4a3d-ab99-51c5dc7bf15b',
        from: 'Miami Art District Store',
        to: 'Fort Myers Beach Boutique',
        units: 25,
        trip_cost: 125,
        expected_sales_increase: 1750,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: 'b30ee3fd-17d5-402b-a6ba-3f2c8375ea09',
        from: 'Fort Myers Beach Boutique',
        to: 'Bridgeview Outfitters',
        units: 37,
        trip_cost: 185,
        expected_sales_increase: 3145,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: '2e454b57-e7fb-4967-a33b-ad898964214b',
        from: 'Miami Art District Store',
        to: 'Tampa University Market',
        units: 32,
        trip_cost: 128,
        expected_sales_increase: 2848,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: 'b615ad80-88b6-44f6-9d87-6d47dc7c5673',
        from: 'Miami Beach Boutique',
        to: 'Jacksonville Beach Boutique',
        units: 14,
        trip_cost: 84,
        expected_sales_increase: 840,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: 'd0f03c3d-e4d2-46b7-aaa5-21ffd087208f',
        from: 'Tampa University Market',
        to: 'Fort Myers Beach Boutique',
        units: 45,
        trip_cost: 225,
        expected_sales_increase: 3465,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: '0c9747c6-dd8e-4b97-be8a-0a4ab7273a83',
        from: 'Archway Boutique',
        to: 'Orlando Magic Market',
        units: 10,
        trip_cost: 60,
        expected_sales_increase: 830,
        total_products: 12,
      },
      {
        cluster_id: '13c4f8dc-f57d-488b-884d-838035502a76',
        id: '9564f44d-adb5-4797-a726-d2d98f09cdf6',
        from: 'Fort Myers Beach Boutique',
        to: 'Miami Art District Store',
        units: 25,
        trip_cost: 125,
        expected_sales_increase: 1750,
        total_products: 12,
      },
    ];

    const data = trips.filter((item) => item.cluster_id === clusterId);

    return { data, meta: { page: 1, per_page: 25, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getTripsHistoryByClusterId(clusterId: string, query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake trips obj when backend is ready
    // return this.transport.get<PaginatedResponse<Trip>>('/v1/store_transfer/trips_history_by_cluster', {
    //   query: { cluster_id: clusterId, ...query },
    // });
    const data: TripHistory[] = [
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'f0dd1054-5c3d-4156-8c4e-9da89915b081',
        from: 'Dallas Conch Republic Market',
        to: 'Western Emporium',
        units: {
          actual: 0,
          approved: 25,
        },
        expected_sales_increase: {
          actual: 0,
          approved: 1525,
        },
        trip_cost: 0,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'c73ba739-9cc1-40e8-9013-0682c7322cd4',
        from: 'San Antonio valley horizon Market',
        to: 'Southern Roots Farm and Market',
        units: {
          actual: 0,
          approved: 74,
        },
        expected_sales_increase: {
          actual: 0,
          approved: 6364,
        },
        trip_cost: 0,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '887a17f9-e107-4fa5-99cd-a44205754bb8',
        from: 'Golden hill  Market',
        to: 'Houston Space City Market',
        units: {
          actual: 48,
          approved: 48,
        },
        expected_sales_increase: {
          actual: 3648,
          approved: 3648,
        },
        trip_cost: 288,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'fc629650-771a-43ae-8ea7-129511b44672',
        from: 'Oklahoma University Outfitter',
        to: 'Pacific Avenue Store',
        units: {
          actual: 31,
          approved: 31,
        },
        expected_sales_increase: {
          actual: 2542,
          approved: 2542,
        },
        trip_cost: 155,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'a39cbb87-9c98-4c1a-a077-4028c56972fc',
        from: 'Western Emporium',
        to: 'Desert Dreams Gift Shop',
        units: {
          actual: 63,
          approved: 63,
        },
        expected_sales_increase: {
          actual: 5670,
          approved: 5670,
        },
        trip_cost: 252,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '6ae47ef1-d301-4328-8376-bf44a94b2ca3',
        from: 'Southern Roots Farm and Market',
        to: 'San Francisco Conch Boutique',
        units: {
          actual: 53,
          approved: 53,
        },
        expected_sales_increase: {
          actual: 3922,
          approved: 3922,
        },
        trip_cost: 318,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'b9bf8a48-29db-4e35-ab38-134658d091fa',
        from: 'Houston Space City Market',
        to: 'Dallas Conch Republic Market',
        units: {
          actual: 85,
          approved: 85,
        },
        expected_sales_increase: {
          actual: 6375,
          approved: 6375,
        },
        trip_cost: 340,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '3d6367c3-d07b-4a7e-9723-fb23ee274330',
        from: 'Pacific Avenue Store',
        to: 'San Antonio valley horizon Market',
        units: {
          actual: 65,
          approved: 65,
        },
        expected_sales_increase: {
          actual: 4030,
          approved: 4030,
        },
        trip_cost: 390,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '93a755e4-4b00-4e06-baf8-835dd507facd',
        from: 'Desert Dreams Gift Shop',
        to: 'Golden hill  Market',
        units: {
          actual: 46,
          approved: 46,
        },
        expected_sales_increase: {
          actual: 2576,
          approved: 2576,
        },
        trip_cost: 184,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'bc3696f4-7e47-486c-96e8-a4c01368f9dc',
        from: 'San Francisco Conch Boutique',
        to: 'Oklahoma University Outfitter',
        units: {
          actual: 54,
          approved: 54,
        },
        expected_sales_increase: {
          actual: 4158,
          approved: 4158,
        },
        trip_cost: 324,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '0236d16f-25bd-4cd3-9a05-2ad8b164b497',
        from: 'Austin Lone Star Market',
        to: 'San Antonio valley horizon Market',
        units: {
          actual: 29,
          approved: 29,
        },
        expected_sales_increase: {
          actual: 2291,
          approved: 2291,
        },
        trip_cost: 87,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'bde0b0fc-8de6-4405-8a55-bb2fedd56ba9',
        from: 'Dallas Conch Republic Market',
        to: 'Oklahoma University Outfitter',
        units: {
          actual: 32,
          approved: 32,
        },
        expected_sales_increase: {
          actual: 2592,
          approved: 2592,
        },
        trip_cost: 224,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: 'a734ef13-6ec6-435d-82bf-cd48bd47541f',
        from: 'San Antonio valley horizon Market',
        to: 'Houston Space City Market',
        units: {
          actual: 70,
          approved: 70,
        },
        expected_sales_increase: {
          actual: 4690,
          approved: 4690,
        },
        trip_cost: 490,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '2212648e-8c4b-4ec2-83f3-fcde6dd35524',
        from: 'Golden hill Market',
        to: 'Austin Lone Star Market',
        units: {
          actual: 51,
          approved: 51,
        },
        expected_sales_increase: {
          actual: 2958,
          approved: 2958,
        },
        trip_cost: 306,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '4bd04b99-2911-45a2-b2bc-b2632dd0ca24',
        from: 'Oklahoma University Outfitter',
        to: 'Golden hill  Market',
        units: {
          actual: 42,
          approved: 42,
        },
        expected_sales_increase: {
          actual: 3570,
          approved: 3570,
        },
        trip_cost: 168,
        total_products: 12,
      },
      {
        cluster_id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        id: '6fed555b-1444-43ab-a0e5-16e4e7738dba',
        from: 'Western Emporium',
        to: 'San Antonio valley horizon Market',
        units: {
          actual: 72,
          approved: 72,
        },
        expected_sales_increase: {
          actual: 5256,
          approved: 5256,
        },
        trip_cost: 360,
        total_products: 12,
      },
    ];

    return { data, meta: { page: 1, per_page: 5, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProductsByTripId(tripId: string, query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake trips obj when backend is ready
    // return this.transport.get<PaginatedResponse<StoreTransferProduct>>('/v1/store_transfer/products_by_trip', {
    //   query: { cluster_id: clusterId, ...query },
    // });
    const data: StoreTransferProduct[] = [
      {
        id: 'b623dd41-da80-4239-8bf9-aa34238e69d7',
        name: 'Puff Sleeve Ruffle Neck Lace Mini Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/thumb',
          },
        ],
        units: 4,
        expected_sales_rate: 240,
      },
      {
        id: '238c6717-8648-41e6-9d50-5e369a968361',
        name: 'Criss-Cross Cutout Mini Dress_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/490f2bc4-1f4f-439c-e03b-41dfafcb6800/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/490f2bc4-1f4f-439c-e03b-41dfafcb6800/thumb',
          },
        ],
        units: 3,
        expected_sales_rate: 228,
      },
      {
        id: '8e13c205-3fa9-48bb-8a9a-ec75fabff777',
        name: 'Flutter Sleeve Midi Dress_Orange',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/030dfd00-a9b8-4530-7a60-df8990966900/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/030dfd00-a9b8-4530-7a60-df8990966900/thumb',
          },
        ],
        units: 3,
        expected_sales_rate: 189,
      },
      {
        id: '6e77a665-0fab-48a6-9742-9faca0b22333',
        name: 'Button-Up Mini Dress_Purple',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/35860d00-9419-484c-29a9-9d6c10a57e00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/35860d00-9419-484c-29a9-9d6c10a57e00/thumb',
          },
        ],
        units: 4,
        expected_sales_rate: 292,
      },
      {
        id: 'b66d3d03-7f4d-4163-9e8a-132a628411dd',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Brown',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/c4c1efd4-c099-491b-2bdf-fd2f601b7d00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/c4c1efd4-c099-491b-2bdf-fd2f601b7d00/thumb',
          },
        ],
        units: 2,
        expected_sales_rate: 134,
      },
      {
        id: '811934f7-cff6-4a77-8ad5-5fd8acb153f0',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Light',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/thumb',
          },
        ],
        units: 5,
        expected_sales_rate: 280,
      },
      {
        id: '84629b34-4723-4280-8079-db7b9927d7b0',
        name: "Mid Rise '90s Loose Carpenter Jeans_Musterd",
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/22fac679-c00b-4c83-d0c8-c2c99ebd6a00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/22fac679-c00b-4c83-d0c8-c2c99ebd6a00/thumb',
          },
        ],
        units: 4,
        expected_sales_rate: 162,
      },
    ];

    return { data, meta: { page: 1, per_page: 25, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStoreTransferProducts(query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake trips obj when backend is ready
    // return this.transport.get<PaginatedResponse<StoreTransferProduct>>('/v1/store_transfer/products', {
    //   query: { ...query },
    // });

    const data: StoreTransferProduct[] = [
      {
        id: 'b623dd41-da80-4239-8bf9-aa34238e69d7',
        name: 'Puff Sleeve Ruffle Neck Lace Mini Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/thumb',
          },
        ],
        units: 39,
        expected_sales_rate: 3116,
      },
      {
        id: 'b0d156b4-2913-47fb-9d28-c2714c5e0e1b',
        name: 'Puff Sleeve Ruffle Neck Lace Mini Dress_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/a702019c-9fd5-40ee-6af6-2cb9b430ad00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/a702019c-9fd5-40ee-6af6-2cb9b430ad00/thumb',
          },
        ],
        units: 43,
        expected_sales_rate: 3470,
      },
      {
        id: '238c6717-8648-41e6-9d50-5e369a968361',
        name: 'Criss-Cross Cutout Mini Dress_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/490f2bc4-1f4f-439c-e03b-41dfafcb6800/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/490f2bc4-1f4f-439c-e03b-41dfafcb6800/thumb',
          },
        ],
        units: 77,
        expected_sales_rate: 5530,
      },
      {
        id: '460e6262-936d-4ee6-99f9-3f877b4f250b',
        name: 'Criss-Cross Cutout Mini Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/0e0d0160-b3f5-4e95-a25e-998cca15ba00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/0e0d0160-b3f5-4e95-a25e-998cca15ba00/thumb',
          },
        ],
        units: 69,
        expected_sales_rate: 5253,
      },
      {
        id: '6ca03e1a-545c-42f0-b56f-2af58a8e9eaa',
        name: 'Flutter Sleeve Midi Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/30b06374-7b8c-49fe-3dd7-2988ffdf4200/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/30b06374-7b8c-49fe-3dd7-2988ffdf4200/thumb',
          },
        ],
        units: 75,
        expected_sales_rate: 5824,
      },
      {
        id: '8e13c205-3fa9-48bb-8a9a-ec75fabff777',
        name: 'Flutter Sleeve Midi Dress_Orange',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/030dfd00-a9b8-4530-7a60-df8990966900/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/030dfd00-a9b8-4530-7a60-df8990966900/thumb',
          },
        ],
        units: 36,
        expected_sales_rate: 3187,
      },
      {
        id: '6ca03e1a-545c-42f0-b56f-2af58a8e9eaa',
        name: 'Low Back Metallic Midi Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/f89e8c9c-e325-4b57-87e1-26d0b9945100/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/f89e8c9c-e325-4b57-87e1-26d0b9945100/thumb',
          },
        ],
        units: 26,
        expected_sales_rate: 2624,
      },
      {
        id: 'df1e86e1-974d-471d-9542-0b95c015d5a2',
        name: 'Low Back Metallic Midi Dress_Grey',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/08c16e89-75d8-4fe6-d8cd-0aef1c8ec600/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/08c16e89-75d8-4fe6-d8cd-0aef1c8ec600/thumb',
          },
        ],
        units: 57,
        expected_sales_rate: 5868,
      },
      {
        id: '6e77a665-0fab-48a6-9742-9faca0b22333',
        name: 'Button-Up Mini Dress_Purple',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/35860d00-9419-484c-29a9-9d6c10a57e00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/35860d00-9419-484c-29a9-9d6c10a57e00/thumb',
          },
        ],
        units: 67,
        expected_sales_rate: 6415,
      },
      {
        id: 'a55ce2da-07db-44aa-9c77-a20e2ae6d3aa',
        name: 'Button-Up Mini Dress_Army',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/caaee4d0-5ed7-4291-e089-f79063809c00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/caaee4d0-5ed7-4291-e089-f79063809c00/thumb',
          },
        ],
        units: 43,
        expected_sales_rate: 4282,
      },
      {
        id: '06c85ab2-dcec-451d-870e-c06a024edf7b',
        name: 'Button-Up Mini Dress_Black',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/8a3e1ced-ad96-4212-654a-495ac30f9300/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/8a3e1ced-ad96-4212-654a-495ac30f9300/thumb',
          },
        ],
        units: 74,
        expected_sales_rate: 6337,
      },
      {
        id: '8cd5a11e-af89-4afd-b776-5b314a82b4d8',
        name: 'Button-Up Mini Dress_Brown',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/a688ed07-088f-4834-6e21-07f15b09e600/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/a688ed07-088f-4834-6e21-07f15b09e600/thumb',
          },
        ],
        units: 36,
        expected_sales_rate: 3299,
      },
      {
        id: '676092c4-57df-45e0-84ba-a5f7bf29869b',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Ruby',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/thumb',
          },
        ],
        units: 28,
        expected_sales_rate: 2643,
      },
      {
        id: 'b66d3d03-7f4d-4163-9e8a-132a628411dd',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Brown',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/c4c1efd4-c099-491b-2bdf-fd2f601b7d00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/c4c1efd4-c099-491b-2bdf-fd2f601b7d00/thumb',
          },
        ],
        units: 31,
        expected_sales_rate: 2604,
      },
      {
        id: '971c8253-3477-4183-9339-7ffbf5aa611d',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Green',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/2363a1ab-af56-48a8-7a35-6199588c7e00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/2363a1ab-af56-48a8-7a35-6199588c7e00/thumb',
          },
        ],
        units: 71,
        expected_sales_rate: 6680,
      },
      {
        id: '9ff88a4f-1ebe-474d-97e0-e3ea3da65dbe',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/thumb',
          },
        ],
        units: 56,
        expected_sales_rate: 6006,
      },
      {
        id: '811934f7-cff6-4a77-8ad5-5fd8acb153f0',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Light',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/thumb',
          },
        ],
        units: 76,
        expected_sales_rate: 9292,
      },
      {
        id: '3dbd7c8f-04d6-4a4d-aa1d-dbb598b929bd',
        name: "Mid Rise '90s Loose Jeans in Organic Cotton_Black",
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/0951e8e1-21f7-4a69-abc3-a03dcd13ec00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/0951e8e1-21f7-4a69-abc3-a03dcd13ec00/thumb',
          },
        ],
        units: 69,
        expected_sales_rate: 7171,
      },
      {
        id: '84629b34-4723-4280-8079-db7b9927d7b0',
        name: "Mid Rise '90s Loose Carpenter Jeans_Musterd",
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/22fac679-c00b-4c83-d0c8-c2c99ebd6a00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/22fac679-c00b-4c83-d0c8-c2c99ebd6a00/thumb',
          },
        ],
        units: 50,
        expected_sales_rate: 4781,
      },
    ];

    return { data, meta: { page: 1, per_page: 5, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStoreTransferProductDetailsById(productId: string) {
    //TODO: Uncomment this and drop the fake product obj when backend is ready
    // return this.transport.get<StoreTransferProductDetails>('/v1/store_transfer/products', {
    //   query: { id: productId },
    // });

    return {
      data: {
        id: 'b623dd41-da80-4239-8bf9-aa34238e69d7',
        name: 'Puff Sleeve Ruffle Neck Lace Mini Dress_Black',
        description: '',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/b97a6772-e0f3-4d16-8fda-8258c0657600/thumb',
          },
        ],
        units: 39,
        expected_sales_rate: 3116,
        stores: 4,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProductChartClusters(query?: PaginationQuery) {
    //TODO: Uncomment this and drop the fake clusters obj when backend is ready
    // return this.transport.get<PaginatedResponse<StoreTransferProductChartCluster>>('/v1/store_transfer/product_chart_clusters', {
    //   query: { ...query },
    // });

    const data: StoreTransferProductChartCluster[] = [
      {
        id: '0471cafa-4b75-4cee-83f0-9bd60b2db956',
        name: 'South West',
        expected_sales_increase: 1480,
        total_trips: 2,
        total_stores: 4,
        total_units: 20,
        chartsData: [
          {
            location_name: 'Southern Roots Farm and Market',
            sale_rate: 4.7,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 0,
                  recieve: 3,
                  send: 0,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Pacific Avenue Store',
            sale_rate: 3.6,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 0,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 0,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Desert Dreams Gift Shop',
            sale_rate: 2.8,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 3,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 4,
                  recieve: 0,
                  send: 1,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 3,
                  recieve: 0,
                  send: 1,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 3,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 2,
                  recieve: 0,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Houston Space City Market',
            sale_rate: 1.1,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 3,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 4,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 3,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
            ],
          },
        ],
      },
      {
        id: '13c4f8dc-f57d-488b-884d-838035502a76',
        name: 'South East',
        expected_sales_increase: 1636,
        total_trips: 3,
        total_stores: 6,
        total_units: 19,
        chartsData: [
          {
            location_name: 'Tampa Bay Sun Store',
            sale_rate: 5.2,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 1,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 1,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 1,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Bridgeview Outfitters',
            sale_rate: 5.2,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 2,
                  recieve: 0,
                  send: 1,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 3,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 3,
                  recieve: 0,
                  send: 1,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 4,
                  recieve: 0,
                  send: 2,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 2,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 2,
                  recieve: 0,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Fort Myers Beach Boutique',
            sale_rate: 3.4,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 0,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 0,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 1,
                  send: 0,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 1,
                  recieve: 1,
                  send: 0,
                },
              },
            ],
          },
          {
            location_name: 'Jacksonville Beach Boutique',
            sale_rate: 0.7,
            data: [
              {
                size: 'xs',
                sizeData: {
                  do_not_move: 0,
                  recieve: 2,
                  send: 1,
                },
              },
              {
                size: 's',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 4,
                },
              },
              {
                size: 'm',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 3,
                },
              },
              {
                size: 'l',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
              {
                size: 'xl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 3,
                },
              },
              {
                size: 'xxl',
                sizeData: {
                  do_not_move: 0,
                  recieve: 0,
                  send: 2,
                },
              },
            ],
          },
        ],
      },
    ];

    return { data, meta: { page: 1, per_page: 25, total: data.length } };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventDetailedKpis(id: string) {
    // return this.transport.get<SpecialEventsDetailsKpis>('/v1/special_events/kpis', {
    //   query: { id },
    // });
    return {
      data: {
        total_categories: 4,
        total_products: 706,
        total_locations: 12,
        total_items: 5432,
        expected_cost: 130000,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventLocationKpis(id: string) {
    // return this.transport.get<SpecialEventsLocationKpis>('/v1/special_events/locations_kpis', {
    //   query: { id },
    // });
    return {
      data: {
        total_units: 22,
        total_skus: 79,
        expected_cost: 1035,
      },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCategoriesByEventId(eventId: string): SpecialEventCategory[] {
    // return this.transport.get<SpecialEventCategory[]>('/v1/special_events/categories_by_event', {
    //   query: { id: eventId },
    // });
    const categories: SpecialEventCategory[] = [
      {
        id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        name: 'Jeans - Slim',
        factor: 11,
      },
      {
        id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        name: 'Dress - Mini',
        factor: 4,
      },
      {
        id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        name: 'Dress - Midi',
        factor: 27,
      },
      {
        id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        name: 'Jeans - Relax',
        factor: 19,
      },
    ];
    return categories;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpecialEventProductsByLocation(locationId: string): SpecialEventProduct[] {
    // return this.transport.get<SpecialEventProduct[]>('/v1/special_events/products_by_location', {
    //   query: { id: locationId },
    // });
    const products: SpecialEventProduct[] = [
      {
        id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        name: 'High Rise Corduroy Vintage Slim Jeans with Washwell_Ruby',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/d59c2b3c-1a35-45fb-4c67-c8613af5fc00/thumb',
          },
        ],
        factor: 30,
        skus: [],
      },
      {
        id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Blue',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4d36eee0-d623-4fb3-6d1a-f5bcfd4b5900/thumb',
          },
        ],
        factor: 25,
        skus: [],
      },
      {
        id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        name: 'Mid Rise Vintage Slim Jeans with Washwell_Light',
        images: [
          {
            domain: 'onebeat.info',
            public:
              'ttps://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/public',
            thumb:
              'https://imagedelivery.net/mKN9Vubler8c-i8kBVh6lQ/4261bcde-b8f0-428f-6c28-addb70205400/thumb',
          },
        ],
        factor: 33,
        skus: [],
      },
    ];
    return products;
  }

  getSpecialEventProductSkus(productId: string): SpecialEventSku[] {
    const skus: SpecialEventSku[] = [
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'b801a67b-139e-41d4-b8e8-53e74ac397f0',
        external_sku_id: 'SK_5013201',
        size: '27',
        units: 4,
        expected_cost: 65.4,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '9d203bcd-d5dc-45b9-981e-fa78b3171e65',
        external_sku_id: 'SK_5013209',
        size: '28',
        units: 1,
        expected_cost: 102,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'e59dab83-bb9d-4c11-8d86-2fbd0b9701e3',
        external_sku_id: 'SK_5013217',
        size: '29',
        units: 6,
        expected_cost: 93,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'ba29f7d1-3ba5-4398-902b-ed5301baecb0',
        external_sku_id: 'SK_5013225',
        size: '30',
        units: 2,
        expected_cost: 30.6,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'f5fd6d5d-0a72-4fcd-a74c-627d16b5b963',
        external_sku_id: 'SK_5013233',
        size: '31',
        units: 2,
        expected_cost: 34.2,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '67df8037-6108-4ce1-90e7-a9925e0a1764',
        external_sku_id: 'SK_5013241',
        size: '32',
        units: 6,
        expected_cost: 85.2,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: '2ee70ada-92fe-4341-9ee1-356066124b80',
        external_sku_id: 'SK_5013249',
        size: '34',
        units: 2,
        expected_cost: 23.4,
      },
      {
        product_id: '5aad50bd-6378-4521-9b67-eb69970e24d7',
        id: 'bfd768ca-c5f0-4451-a39c-8ff4935c5cb8',
        external_sku_id: 'SK_5013257',
        size: '35',
        units: 5,
        expected_cost: 64.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '3bdd7475-15ab-44d1-bc22-c2260b4651a2',
        external_sku_id: 'SK_3528025',
        size: '27',
        units: 5,
        expected_cost: 64.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'e12c96f7-a3cc-422c-975f-de6230c27398',
        external_sku_id: 'SK_3528033',
        size: '28',
        units: 5,
        expected_cost: 41.4,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '2606e912-7fc6-4cc0-8223-4ebab3338bd3',
        external_sku_id: 'SK_3528041',
        size: '29',
        units: 3,
        expected_cost: 34.2,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'ed212f7b-3eee-4121-ba77-dc38e7ddf397',
        external_sku_id: 'SK_3528049',
        size: '30',
        units: 3,
        expected_cost: 27,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '0e7e773d-d1f7-4139-89b4-e36313b54430',
        external_sku_id: 'SK_3528057',
        size: '31',
        units: 1,
        expected_cost: 14.4,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: '24d8342b-0167-461d-af84-9423e95ac889',
        external_sku_id: 'SK_3528065',
        size: '32',
        units: 5,
        expected_cost: 58.8,
      },
      {
        product_id: '6a2311f1-f00c-4d3f-bd33-9213bb8e64ac',
        id: 'cf80fd55-574b-47ad-be50-d8a178824ebf',
        external_sku_id: 'SK_3528073',
        size: '34',
        units: 1,
        expected_cost: 9.6,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '8c285827-62ec-4a7c-92b4-8d003a99f0d7',
        external_sku_id: 'SK_3528097',
        size: '25',
        units: 4,
        expected_cost: 71.4,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '6ed90142-d184-49f1-9f0f-7916398fad2b',
        external_sku_id: 'SK_3528105',
        size: '26',
        units: 5,
        expected_cost: 58.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '96025fc3-1d35-43c6-8307-f3e8c631b423',
        external_sku_id: 'SK_3528113',
        size: '27',
        units: 4,
        expected_cost: 64.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: 'd71e726a-72ba-4259-834d-ce837be735d5',
        external_sku_id: 'SK_3528121',
        size: '28',
        units: 5,
        expected_cost: 55.2,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: 'ccfb68cf-8625-4a6d-bfc5-fffee6006ceb',
        external_sku_id: 'SK_3528129',
        size: '29',
        units: 2,
        expected_cost: 36,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '99015971-68b1-4fa2-ae9a-46fca948a9a7',
        external_sku_id: 'SK_3528137',
        size: '30',
        units: 4,
        expected_cost: 48.6,
      },
      {
        product_id: '7b3660c1-cfaf-40d5-8dac-f3c385010aef',
        id: '05c96942-a97e-4f27-a6fd-4539c60d4170',
        external_sku_id: 'SK_3528145',
        size: '31',
        units: 4,
        expected_cost: 47.4,
      },
    ];

    const data = skus.filter((item) => item.product_id === productId);

    return data;
  }

  getlocationsByCategoryId(id: string): PaginatedResponse<SpecialEventLocation> {
    const locations: SpecialEventLocation[] = [
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '8579a73d-183f-45f0-ab54-a6f7d52ce76f',
        name: 'Indiana Paradise',
        factor: 30,
        number_of_products: 47,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '04855510-9061-4345-9726-048dc68739c0',
        name: 'NY City Market',
        factor: 45,
        number_of_products: 62,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: 'a22355ff-7716-409e-a727-72f87907c467',
        name: 'Orlando Magic Market',
        factor: 25,
        number_of_products: 34,
      },
      {
        category_id: 'e4f89821-0fe7-42c6-8c15-6d5780cae1b6',
        id: '57d3907a-3790-41d3-be63-771ac801c58b',
        name: 'Golden hill Market',
        factor: 10,
        number_of_products: 55,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '8579a73d-183f-45f0-ab54-a6f7d52ce76f',
        name: 'Oklahoma University',
        factor: 25,
        number_of_products: 47,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '04855510-9061-4345-9726-048dc68739c0',
        name: 'HNY City Marketaifa',
        factor: 33,
        number_of_products: 62,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: '8cb1114b-cc81-4674-a869-a80d76029534',
        name: 'Western Emporium',
        factor: 25,
        number_of_products: 34,
      },
      {
        category_id: 'b659455e-1ed9-4c13-aded-ba54c39f83b1',
        id: 'a22355ff-7716-409e-a727-72f87907c467',
        name: 'Golden hill Market',
        factor: 15,
        number_of_products: 55,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '28cb690b-9599-4c05-9c95-2ec3fa49ace5',
        name: 'Tampa Bay Sun',
        factor: 40,
        number_of_products: 23,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '39d35f16-0b84-48e1-b179-02d426353c2a',
        name: 'New Orleans Jazz Market',
        factor: 25,
        number_of_products: 31,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: '548f7237-65e9-470b-b978-7c7c00b84581',
        name: 'Jacksonville Beach Boutique',
        factor: 35,
        number_of_products: 41,
      },
      {
        category_id: 'd1d303d3-7f4a-443f-900e-79e3d041812c',
        id: 'efa07def-5f21-4fb7-a55d-3e04417dd0c7',
        name: 'NY City Market',
        factor: 30,
        number_of_products: 78,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'dc0702a2-0222-48ce-ad22-3b82ddd61b74',
        name: 'Horizon view Mall',
        factor: 25,
        number_of_products: 21,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'f7ec0b3a-f200-4e18-a163-6e1e64d9f59b',
        name: 'Fort Myers Beach',
        factor: 25,
        number_of_products: 67,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: '58fd5134-6f19-4ff4-a78f-4ef9a9240373',
        name: 'Tampa Bay Sun',
        factor: 25,
        number_of_products: 28,
      },
      {
        category_id: '46128d4f-5e1e-4b21-ad09-57674b036f55',
        id: 'ccd82197-b3fe-475d-9f92-ae211fd3ae70',
        name: 'Needle point',
        factor: 25,
        number_of_products: 21,
      },
    ];

    const data = locations.filter((item) => item.category_id === id);

    return {
      data,
      meta: {
        page: 1,
        per_page: 25,
        total: data.length,
      },
    };
  }
}
