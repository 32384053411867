import type { ComputedRef, InjectionKey } from 'vue';
import type { ObConfig } from './types';

export const defaultConfig: ObConfig = {
  numberFormat: {
    decimalSeparator: '.',
    thousandSeparator: ',',
  },
};

export const CONFIG_INJECTION_KEY: InjectionKey<ComputedRef<ObConfig>> = Symbol('Config');
