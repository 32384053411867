<script lang="ts" setup>
import { computed } from 'vue';
import type { SVGAttributes, FunctionalComponent, DefineComponent } from 'vue';
import isNil from 'lodash/isNil';
import { SizeS, SizeM, SizeL } from '../../shared/types';
import { pxOrValue } from '../../utils';

interface Props {
  component?: DefineComponent | FunctionalComponent; // TODO: do test case or remove
  fill?: 'none' | 'currentColor' | string;
  inheritViewBox?: boolean;
  size?: SizeS | SizeM | SizeL | 'auto' | string | number;
  stroke?: 'none' | 'currentColor' | string;
  strokeLinecap?: 'butt' | 'round' | 'square';
  strokeLinejoin?: 'round' | 'bevel' | 'miter';
  strokeWidth?: number | string;
  title?: string;
  viewBox: string;
}

const props = withDefaults(defineProps<Props>(), {
  component: undefined,
  fill: 'currentColor',
  inheritViewBox: true,
  size: 'auto',
  stroke: 'none',
  strokeLinecap: undefined,
  strokeLinejoin: undefined,
  strokeWidth: undefined,
  title: undefined,
});

const ALLOWED_ICON_SIZES = ['s', 'm', 'l'];

const svgProps = computed<SVGAttributes>(() => {
  const attrs: SVGAttributes = {
    'aria-hidden': props.title ? undefined : true,
    class: ['ob-svg-icon'],
    fill: props.fill,
    focusable: 'false',
    role: props.title ? 'img' : undefined,
    viewBox: props.viewBox,
  };

  if (typeof props.size === 'string' && ALLOWED_ICON_SIZES.includes(props.size)) {
    attrs.class.push(`_size-${props.size}`);
  } else if (props.size != 'auto' && !isNil(props.size)) {
    attrs.style = {
      fontSize: pxOrValue(props.size),
    };
  }

  if (props.stroke && props.stroke !== 'none') {
    attrs.stroke = props.stroke;
    attrs['stroke-width'] = props.strokeWidth;
    attrs['stroke-linecap'] = props.strokeLinecap;
    attrs['stroke-linejoin'] = props.strokeLinejoin;
  }

  if (props.component) {
    if (props.inheritViewBox) {
      delete attrs.viewBox;
    }

    return attrs;
  }

  return attrs;
});
</script>

<template>
  <component :is="props.component" v-if="props.component" v-bind="svgProps" />
  <svg v-else v-bind="svgProps">
    <title v-if="props.title">{{ props.title }}</title>
    <slot />
  </svg>
</template>

<style lang="scss">
@use '../../styles/iconography';

.ob-svg-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;

  &._size-s {
    font-size: iconography.$icon-size-s;
  }

  &._size-m {
    font-size: iconography.$icon-size-m;
  }

  &._size-l {
    font-size: iconography.$icon-size-l;
  }
}
</style>
