{
  "common": {
    "actions": {
      "apply": "Apply",
      "clear": "Clear",
      "clearFilters": "Clear filters",
      "download": "Download",
      "downloadChartImage": "Download chart image",
      "openColumnsVisibilitySettings": "Columns",
      "openFilters": "Filter",
      "openMetricsVisibilitySettings": "Metrics",
      "openSortSettings": "Sort",
      "viewLess": "View less",
      "viewMore": "View more"
    },
    "statuses": {
      "lastUpdateTimeAgo": "Updated {timeAgo}",
      "loading": "Loading",
      "noColumnsFound": "No columns found",
      "noData": "No data",
      "noMetricsFound": "No metrics found",
      "noMetricsSelected": "No metrics selected",
      "paginationState": "{min}-{max} of {total} items"
    },
    "values": {
      "all": "All",
      "no": "No",
      "yes": "Yes"
    }
  },
  "insufficientReplenishment": {
    "datasetsNames": {
      "replenishment": "Replenishment",
      "salesRate": "Sales rate",
      "stock": "Stock"
    },
    "properties": {
      "brand": "Brand",
      "category": "Category",
      "color": "Color",
      "country": "Country",
      "creationDate": "Creation date",
      "department": "Department",
      "price": "Price",
      "product": "Product",
      "region": "Region",
      "replenishment": "Replenishment",
      "replenishmentTime": "Replenishment time",
      "season": "Season",
      "size": "Size",
      "style": "Style",
      "supply": "Supply",
      "warehouse": "WH"
    },
    "title": "Insufficient replenishment"
  },
  "inventory": {
    "common": {
      "actions": {
        "avoidReplenishment": "Avoid replenishment",
        "unavoidReplenishment": "Unavoid replenishment"
      },
      "messages": {
        "avoidReplenishmentConfirmation": "Replenishment for the selected items will be stopped. Are you sure you want to avoid it?"
      }
    },
    "locations": {
      "kpi": {
        "inventoryCost": "Inventory cost",
        "totalInventory": "Inventory",
        "totalLocations": "Locations"
      },
      "properties": {
        "brand": "Brand",
        "city": "City",
        "classification": "Classification",
        "creationDate": "Creation date",
        "inventoryAtSite": "At site",
        "inventoryCost": "Inventory cost",
        "inventoryInTransit": "In transit",
        "inventoryPrice": "Inventory price",
        "inventoryReserved": "Reserved",
        "locationType": "Location type",
        "name": "Name",
        "numberOfProducts": "# products",
        "numberOfSkus": "# skus",
        "region": "Region",
        "replenishmentAvoided": "Avoid replenishment"
      },
      "statuses": {
        "noLocationsFound": "No locations found"
      },
      "title": "Location"
    },
    "skuChart": {
      "actions": {
        "downloadSkuHistoryReport": "Download SKU History report (CSV)"
      },
      "metrics": {
        "coverage": "Coverage",
        "inventoryAtSite": "Inventory at site",
        "inventoryAtWarehouse": "At WH",
        "inventoryInTransit": "In transit",
        "inventoryReserved": "Reserved",
        "optimalStock": "Optimal stock",
        "replenishmentRecommendation": "Replenishment recommendation",
        "salesRate": "Sales rate",
        "surplus": "Surplus",
        "transactionsIn": "In",
        "transactionsOut": "Out",
        "transactionsSale": "Sale"
      },
      "metricsGroups": {
        "calculations": "Calculations",
        "inventory": "Inventory level",
        "transactions": "Transactions"
      },
      "properties": {
        "brand": "Brand",
        "catalogPrice": "Catalog price",
        "category": "Category",
        "city": "City",
        "color": "Color",
        "cost": "Cost",
        "creationDate": "Creation date",
        "department": "Department",
        "location": "Location",
        "locationBrand": "Location brand",
        "locationClassification": "Location classification",
        "locationType": "Location type",
        "product": "Product",
        "region": "Region",
        "replenishmentTime": "Replenishment time",
        "season": "Season",
        "size": "Size",
        "sku": "SKU",
        "sourceId": "Source ID",
        "style": "Style",
        "targetMarket": "Target market",
        "updateDate": "Update date"
      },
      "title": "SKU history chart"
    },
    "skus": {
      "kpi": {
        "dailySales": "Daily sales",
        "totalInventory": "Inventory",
        "totalSkus": "SKUs"
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "color": "Color",
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "department": "Department",
        "departmentId": "Department ID",
        "departmentName": "Department name",
        "market": "Market",
        "numberOfStores": "# stores",
        "packConstraint": "Pack constraint",
        "price": "Price",
        "product": "Product",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "replenishmentAvoided": "Avoid replenishment",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "skuId": "SKU ID",
        "skuName": "SKU name",
        "style": "Style"
      },
      "statuses": {
        "noSkusFound": "No SKUs found"
      },
      "title": "SKU"
    },
    "skusLocations": {
      "kpi": {
        "totalInventory": "Inventory",
        "totalLocations": "Locations",
        "totalSkus": "SKUs"
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "city": "City",
        "classification": "Classification",
        "color": "Color",
        "cost": "Cost",
        "coverage": "Coverage",
        "creationDate": "Creation date",
        "departmentId": "Department ID",
        "departmentName": "Department name",
        "location": "Location",
        "locationType": "Location type",
        "market": "Market",
        "optimalStock": "Optimal stock",
        "price": "Price",
        "product": "Product",
        "quantityAtSite": "At site",
        "quantityAtWarehouse": "At WH",
        "quantityInTransit": "In transit",
        "region": "Region",
        "replenishmentAvoided": "Avoid replenishment",
        "replenishmentAvoidedForLocation": "Avoid replenishment (location)",
        "replenishmentAvoidedForSku": "Avoid replenishment (SKU)",
        "reservedQuantity": "Reserved",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "skuId": "SKU ID",
        "skuName": "SKU name",
        "style": "Style",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noInventoriesFound": "No inventories found"
      },
      "title": "SKU-Location"
    },
    "title": "Inventory"
  },
  "replenishment": {
    "common": {
      "actions": {
        "downloadReplenishmentPickingList": "Download replenishment picking list (CSV)"
      },
      "kpi": {
        "products": "Products",
        "replenishment": "Replenishment",
        "skus": "SKU",
        "stores": "Stores"
      }
    },
    "constraints": {
      "names": {
        "depleted": "Depleted WH",
        "insufficient": "Insufficient supply",
        "package": "Package constraint",
        "shipmentSchedule": "Schedule constraint"
      }
    },
    "overview": {
      "actions": {
        "downloadReplenishmentOverview": "Download replenishment overview (CSV)"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "replenishment": "Replenishment",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH"
      },
      "title": "Overview"
    },
    "products": {
      "description": {
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the SKU at the store can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "expectedCoverage": "The number of days the replenished inventory of the SKU at the store is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate",
        "numberOfStores": "The number of stores expected to receive replenishment for the SKU.",
        "optimalStock": "The optimal inventory level for the SKU at the store",
        "replenishment": "The number of units recommended for the SKU replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store’s shelves.",
        "salesRate": "The average number of units sold per day, on days the SKU was available for shoppers at the store.",
        "sold": "The number of units the SKU sold since the last time it received replenishment at the store.",
        "stock": "The current inventory level at the store, including inventory that is already on route. Hover on a store SKU’s stock to view the inventory breakdown.",
        "warehouseInventory": "The number of units currently available at the supplying warehouse (prior to today’s replenishment).",
        "warehouseName": "The name of the warehouse sending replenishment for the SKU at the store."
      },
      "properties": {
        "brand": "Brand",
        "category": "Category",
        "color": "Color",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "expectedCoverage": "Expected coverage",
        "numberOfStores": "# stores",
        "optimalStock": "Optimal stock",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "salesRate": "Sales rate",
        "season": "Season",
        "size": "Size",
        "skuId": "SKU ID",
        "skuName": "SKU name",
        "sold": "Sold",
        "stock": "Stock",
        "style": "Style",
        "targetMarket": "Target market",
        "totalReplenishment": "Total replenishment",
        "unitCost": "Unit cost",
        "unitPrice": "Unit price",
        "warehouseInventory": "WH inventory",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noProductsFound": "No products found"
      },
      "title": "Product"
    },
    "stores": {
      "actions": {
        "downloadReplenishmentReport": "Download replenishment report"
      },
      "description": {
        "averageDailySales": "The average daily expected income generated by the store’s SKUs (that have available supply at the WH). Calculated according to the catalog price",
        "averageDailySalesDepleted": "The average daily expected income generated by the store’s SKUs (that do not have available supply at the WH). Calculated according to the current sales rate and catalog price.",
        "constraints": "Shipment constraints that affected the replenishment recommendation.",
        "coverage": "The number of days the current inventory of the store’s SKUs (that have available supply at the WH) can sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "coverageDepleted": "The number of days the current inventory of the store’s SKUs that do not have available supply at the WH can sustain until it runs out of stock, assuming current demand remains relatively steady.",
        "expectedCoverage": "The number of days the replenished inventory of the store’s SKUs is expected to sustain. Assuming current demand remains relatively steady, expected sales are calculated according to the current sales rate and catalog price.",
        "numberOfProducts": "The number of different products that are expected to receive replenishment.",
        "numberOfSkus": "The number of different SKUs (product-size) that are expected to receive replenishment",
        "replenishment": "The total number of units recommended for replenishment to the store.",
        "replenishmentTime": "The time it takes for replenished items to reach the store’s shelves.",
        "sold": "The number of units sold by SKUs (that have available supply at the WH) since the last time each SKU was replenished at the store.",
        "soldDepleted": "The number of units sold by SKUs (that need replenishment but do not have available supply at the WH) since the last time each SKU was replenished at the store.",
        "warehouse": "The name of the warehouse sending replenishment to the store"
      },
      "properties": {
        "averageDailySales": "AVG daily sales",
        "averageDailySalesDepleted": "AVG daily sales n/a at WH",
        "brand": "Brand",
        "city": "City",
        "classification": "Classification",
        "constraints": "Constraints",
        "coverage": "Coverage",
        "coverageDepleted": "Coverage n/a at WH",
        "expectedCoverage": "Expected coverage",
        "locationType": "Location type",
        "numberOfProducts": "# products",
        "numberOfSkus": "# skus",
        "region": "Region",
        "replenishment": "Replenishment",
        "replenishmentTime": "Replenishment time",
        "sold": "Sold",
        "soldDepleted": "Sold n/a at WH",
        "storeName": "Store name",
        "warehouseName": "WH name"
      },
      "statuses": {
        "noStoresFound": "No stores found"
      },
      "title": "Store"
    },
    "title": "Replenishment"
  },
  "validationErrors": {
    "plurals": {
      "symbol": "no symbol | {n} symbol | {n} symbols"
    },
    "string": {
      "min": "Min length is @:validationErrors.plurals.symbol"
    }
  }
}
