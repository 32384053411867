import { createI18n as _createI18n, useI18n as _useI18n } from 'vue-i18n';
import type { UseI18nOptions, I18n, Composer, I18nMode, VueI18n, Locale } from 'vue-i18n';
import type { NumberFormatOptions, DateTimeFormatOptions } from '@intlify/core-base';
import { nextTick, isRef } from 'vue';
import { intlFormatDistance } from 'date-fns';
import { constant } from '../../ui-kit';
import { datetimeFormats, numberFormats } from './formats';
import enUS from './locales/en-us.json';

type MessageSchema = typeof enUS;

declare module 'vue-i18n' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefineLocaleMessage extends MessageSchema {}

  export interface DefineDateTimeFormat {
    datetime: DateTimeFormatOptions;
    date: DateTimeFormatOptions;
    day: DateTimeFormatOptions;
  }

  export interface DefineNumberFormat {
    pretty: NumberFormatOptions;
    compact: NumberFormatOptions;
    money: NumberFormatOptions;
  }
}

export const DEFAULT_LOCALE = 'en-US';

export const LOCALES = [
  { code: 'en-US', language: 'English' },
  { code: 'pt-BR', language: 'Português' },
  { code: 'es-MX', language: 'Español' },
  { code: 'ja-JP', language: '日本語' },
];

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

let i18n: I18n;

export function createI18n() {
  i18n = _createI18n<[MessageSchema], string, false>({
    legacy: false,
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    messages: {
      'en-US': enUS,
    },
    datetimeFormats: {
      'en-US': datetimeFormats,
      'pt-BR': datetimeFormats,
      'es-MX': datetimeFormats,
      'ja-JP': datetimeFormats,
    },
    numberFormats: {
      'en-US': numberFormats,
      'pt-BR': numberFormats,
      'es-MX': numberFormats,
      'ja-JP': numberFormats,
    },
  });

  return i18n;
}

export function useI18n(options?: UseI18nOptions) {
  return _useI18n(options);
}

export async function loadLocaleMessages(locale: string) {
  const messages = await import(`./locales/${locale.toLowerCase()}.json`).then(
    (r: any) => r.default || r,
  );

  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}

export async function setI18nLanguage(locale: string) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/getCanonicalLocales
  // @ts-expect-error https://github.com/microsoft/TypeScript/issues/29129
  const [canonicalLocale] = Intl.getCanonicalLocales(locale);

  // Load unavailable but supported locale
  if (
    !i18n.global.availableLocales.includes(canonicalLocale) &&
    LOCALES.find(({ code }) => code === canonicalLocale)
  ) {
    await loadLocaleMessages(canonicalLocale);
  }

  setLocale(i18n, locale);
}

export function formatTimeRelativeToNow(date: Date | string | number): string {
  const { locale } = _useI18n<Locale>({
    useScope: 'global',
  });

  return intlFormatDistance(new Date(date), new Date(), {
    locale: locale.value,
  });
}

export function getDecimalSeparator(locale: string): string {
  return (
    Intl.NumberFormat(locale)
      .formatToParts(1.1)
      .find((part) => part.type === 'decimal')?.value || '.'
  );
}

export function getThousandSeparator(locale: string): string {
  return (
    Intl.NumberFormat(locale)
      .formatToParts(1000)
      .find((part) => part.type === 'group')?.value || constant.CHAR_NO_BREAK_SPACE
  );
}

// We can add more but it makes no sense
// https://en.wikipedia.org/wiki/Right-to-left_script
const RTL_LOCALES = ['ar', 'fa', 'he'];

export function getLocaleTextDirection(locale: string): 'ltr' | 'rtl' {
  const localeObject = new Intl.Locale(locale);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/textInfo
  if ('textInfo' in localeObject) {
    return (localeObject.textInfo as any).direction;
  }

  return RTL_LOCALES.includes(localeObject.language) ? 'rtl' : 'ltr';
}
