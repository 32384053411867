import { computed, provide, inject } from 'vue';
import type { MaybeRef } from '@vueuse/core';
import type { ComputedRef, InjectionKey } from 'vue';
import { useStack } from '../use-stack';

type StackName = 'root' | 'sidePages' | 'modals';

interface UseZIndexStackReturn {
  zIndex: ComputedRef<number | undefined>;
}

interface UseZIndexStackOptions {
  size?: number;
}

const Z_INDEX_STACK_INJECTION_KEY: InjectionKey<StackName> = Symbol('zIndexStack');

const Z_INDEX_STACK_PARAMS: Record<StackName, { base: number }> = {
  root: {
    base: 1000,
  },
  sidePages: {
    base: 2000,
  },
  modals: {
    base: 3000,
  },
};

export function useZIndexStack(
  name: StackName,
  active: MaybeRef<boolean>,
  options?: UseZIndexStackOptions,
): UseZIndexStackReturn {
  const payload = computed(() => ({
    size: options?.size ?? 1,
  }));

  const { index, exists, first, stack } = useStack<{ size: number }>(name, {
    active,
    payload,
  });

  const { base } = Z_INDEX_STACK_PARAMS[name];

  const zIndex = computed<number | undefined>(() => {
    if (!exists.value) return undefined;
    if (first.value) return base;

    return (
      base + stack.value.slice(0, index.value).reduce((acc, { payload }) => acc + payload.size, 0)
    );
  });

  provide(Z_INDEX_STACK_INJECTION_KEY, name);

  return {
    zIndex,
  };
}

export function useParentZIndexStack(active: MaybeRef<boolean>, options?: UseZIndexStackOptions) {
  const stackId = inject(Z_INDEX_STACK_INJECTION_KEY, 'root');

  return useZIndexStack(stackId, active, options);
}
