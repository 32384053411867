<script setup lang="ts">
import { onBeforeMount, ref, computed } from 'vue';
import { useRoute, useRouter, RouterView } from 'vue-router';
import { until } from '@vueuse/core';
import { useHead } from '@vueuse/head';
import { useApi } from '../services/api';
import type { AuthStateChangeEvent } from '../services/api';
import { getHeap } from '../services/heap';
import { setI18nLanguage, LOCALES, DEFAULT_LOCALE } from '../services/i18n';
import { useFlagsStatus, unleashClient } from '../services/unleash';
import { useUserStore, useMeilisearchStore, useDataStore, useI18nStore } from '../store';
import { runGuards } from '../router';
import { ObConfigProvider, ObToastsProvider } from '../ui-kit';
import type { ObConfig } from '../ui-kit';
import { useAppReady } from './use-app-ready';

const router = useRouter();
const route = useRoute();
const { emit: emitAppReady } = useAppReady();
const userStore = useUserStore();
const meilisearchStore = useMeilisearchStore();
const dataStore = useDataStore();
const i18nStore = useI18nStore();
const api = useApi();
const { flagsReady } = useFlagsStatus();

const initializing = ref(true);
const error = ref(false);

async function reRunCurrentRouteGuards() {
  const result = await runGuards(route, route, { api });

  if (result === false) {
    await router.push('/');
  } else if (typeof result !== 'undefined' && result !== true) {
    await router.push(result);
  }
}

async function handleAuthStateChange(
  event: AuthStateChangeEvent,
  initial?: boolean,
): Promise<void> {
  const heap = getHeap();

  if (event.authenticated) {
    await userStore.fetch();
    dataStore.fetch().catch((err) => {
      // TODO: Handle error
      console.error(err);
    });

    await meilisearchStore.fetchIndexes();

    if (heap) {
      heap.resetIdentity();
      heap.identify(userStore.id);
      heap.addUserProperties({
        name: userStore.name,
        email: userStore.email,
      });
    }

    if (unleashClient.value) {
      unleashClient.value.setContextField('userId', userStore.id);
      unleashClient.value.setContextField('accountId', userStore.account_id);
    }
  } else {
    userStore.clear();
    meilisearchStore.clear();
    dataStore.clear();

    if (heap) {
      heap.resetIdentity();
    }

    if (unleashClient.value) {
      unleashClient.value.setContextField('userId', '');
      unleashClient.value.setContextField('accountId', '');
    }
  }

  // Simply check if user can stay on current route
  if (!initial) {
    reRunCurrentRouteGuards();
    // TODO: handle possible error
  }
}

const onebeatUiConfig = computed<ObConfig>(() => ({
  numberFormat: {
    decimalSeparator: i18nStore.decimalSeparator,
    thousandSeparator: i18nStore.thousandSeparator,
  },
}));

// Check if user can stay on current route after feature flags update
unleashClient.value?.on('update', () => {
  reRunCurrentRouteGuards();
});

onBeforeMount(async () => {
  try {
    // Variable exists only if unleashed initialized
    if (flagsReady) {
      await until(flagsReady).toBe(true);
    }
    // TODO: throw error if flagsError or just ignore (report to sentry) and work without flags?

    // Manage i18n
    let locale = i18nStore.locale || window.navigator.language;
    if (!LOCALES.find(({ code }) => code === locale)) {
      locale = DEFAULT_LOCALE;
    }

    setI18nLanguage(locale);
    i18nStore.setLocale(locale);

    // Wait auth
    await api.auth.isReady();

    // Handle initial auth state manually
    await handleAuthStateChange({ authenticated: api.auth.isAuthenticated() }, true);

    api.auth.onStateChanged((event) => handleAuthStateChange(event, false));

    emitAppReady();

    // Wait initial router navigation
    await router.isReady();
  } catch (err) {
    console.error(err);
    error.value = true;
  } finally {
    initializing.value = false;
  }
});

useHead(
  computed(() => ({
    htmlAttrs: {
      lang: i18nStore.locale,
      dir: i18nStore.textDirection,
    },
  })),
);
</script>

<template>
  <ObConfigProvider :config="onebeatUiConfig">
    <ObToastsProvider>
      <div id="q-app">
        <div
          v-if="initializing"
          class="column justify-center content-center text-center"
          style="height: 100vh"
        >
          <q-spinner color="primary" size="4em" :thickness="2" />
        </div>
        <div v-else-if="error">Error</div>
        <RouterView v-else />
      </div>
    </ObToastsProvider>
  </ObConfigProvider>
</template>

<style>
#app,
.q-dialog {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #35416d;
  font-size: 14px !important;
}

body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: #085cd9;
}
.notification-message {
  background-color: #68739d !important;
  position: fixed !important;
  left: 10vmin;
  top: 2vmin;
  width: 500px !important;
}

.notification-success {
  background-color: #25b592 !important;
  position: fixed !important;
  left: 10vmin;
  top: 2vmin;
  width: 500px !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100vh !important;
}

.q-dialog .q-btn {
  font-size: 16px !important;
}

.bg-avatar-color {
  background: #907ff5;
}
.text-avatar-color {
  color: #ffffff;
}
.text-spinner-color {
  color: #35416d;
}

.bg-apply-color {
  background: #f42287 !important;
}

.text-cancel-color {
  color: #021148 !important;
}

.q-field__label {
  color: #8d94ad !important;
}

.q-field__control:after {
  color: #604f92;
}

.q-tab .q-hoverable:hover > .q-focus-helper {
  background: none !important;
}

.q-tab .q-focus-helper {
  background: none !important;
}

.q-tab:hover {
  color: #604f92 !important;
}

.q-manual-focusable--focused {
  background: #f2f0fe !important;
}
.q-manual-focusable {
  color: #35416d;
}
.q-manual-focusable--focused > .q-focus-helper {
  opacity: 0 !important;
}

.q-item--active,
.q-item--active .q-item__label,
.q-item--active .q-item__label > span {
  color: #f42287 !important;
}

.q-item__label > span,
.q-item__label {
  font-family: 'Poppins';
  color: #35416d;
}

.q-field__native {
  color: #35416d !important;
}

.text-checkbox-color {
  color: #f42287 !important;
}

.multi-li18ne {
  white-space: pre-line;
}
</style>
